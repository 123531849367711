<template>
    <div v-if="state.message">
        <Message :severity="state.message.kind"
                :closable="state.message.kind != 'error'"
        >{{ state.message.message }}</Message>
    </div>
    <div v-if="!state.loading">
        <FormToolbar @save="save" @cancel="cancel" @delete="del" @copy="copy"
                        :hasErrors="state.hasErrors" :buttons="buttons"
                    v-if="toolbar == 'both' || toolbar == 'top'"
                    />
        <FormBuilder :spec="state.model" :form="form" :object="state.object" :initial="state.initial" :errors="state.errors"  v-model:hasErrors="state.hasErrors" />
        <FormToolbar @save="save" @cancel="cancel" @delete="del" @copy="copy"
                        :hasErrors="state.hasErrors" :buttons="buttons"
                    v-if="toolbar == 'both' || toolbar == 'bottom'" />
    </div>
    <ProgressSpinner v-else/>
    <Toast />
    <ConfirmDialog />
</template>

<script>
import { reactive, computed, watch }   from 'vue'
import { useRouter }            from 'vue-router'
import models                   from '@/store/models'
import auth                     from "@/store/auth"
import FormBuilder              from '@/components/models/FormBuilder'
import Toast                    from 'primevue/toast'
import Message                  from 'primevue/message'
import FormToolbar              from '@/components/models/FormToolbar';
import ConfirmDialog            from 'primevue/confirmdialog';
import { useConfirm }           from "primevue/useconfirm";
import ProgressSpinner          from 'primevue/progressspinner';
export default {
    props: {
        modelName: {type: String, required: true},
        formName:  {type: String},
        id:        {type: [String, Number], required: true},
        asNew:     {type: String, default: "false"},
        toolbar:   {type: String, default: "both"},
        buttons:   {type: String, default: "dc"},
        useRouter: {type: Boolean, default: true},
        loading:   {type: Boolean, default: true},
        model:     {type: Object},
        object:    {type: Object},
        overrides: {type: Object, default() {return {}}}
    },
    emits: ["update:loading", "update:model", "update:object", "edit-finished", "revert-function"],
    setup (props, {emit}) {
        const state = reactive ({
            loading : props.loading,
            model:    props.model,
            message:  null,
            object:   null,
            initial:  {},
            errors:   {},
            haserrors: false,
        })
        watch (() => state.loading, (nV) => {
            emit ("update:loading", nV)
        })
        watch (() => state.model, (nV) => {
            emit ("update:model", nV)
        })
        watch (() => state.object, (nV) => {
            emit ("update:object", nV)
        })
        const aState = auth.getAuthState ()
        models.setupGetObject    (props, state)
        const router  = useRouter  ()
        const confirm = useConfirm ()
        const  form = computed (() => {
            let formName = props.formName
            if (aState.user.groups.MODEL_EDIT != true) formName = "description"
            return state.model && state.model.form [formName]
        })
        const backToList = (action, object) => {
            if (props.useRouter) {
                router.push ({
                    name: "module-definition",
                    params: {modelName: props.modelName}})
            } else {
                emit ("edit-finished", {action, object})
            }
        }
        const save = async () => {
            const pk     = state.object [state.model.form.pk]
            models.revertObject (state.object, props.overrides)
            const result = await models.saveObject (props.modelName, pk, state.object)
            if (result.status === true)
                backToList ("save", result.object)
            else {
                if (result.message)
                    state.message = {kind: "error", message: result.message}
                state.errors = result.errors
            }
        }
        const cancel = () => {
            models.revertObject (state.object, state.initial)
            backToList          ("cancel", state.object)
        }
        const del = () => {
            confirm.require({
                message: 'Are you sure you want to delete this object?',
                header: 'Confirmation',
                icon: 'fa-solid fa-exclamation-triangle',
                acceptIcon: "fa-solid fa-trash",
                acceptLabel: "Yes",
                rejectIcon: "fa-solid fa-times",
                accept: async () => {
                    const result = await models.deleteObject (state.object, props.modelName)
                    console.log ("Delete", state.object, result)
                    if (result === true)
                        backToList ("delete", state.object)
                }
            })
        }
        const copy = async () => {
            const newObject = models.copyObject (state.object, state.model.form.pk)
            models.revertObject (state.object, props.overrides)
            const result = await models.saveObject (props.modelName, -1, newObject)
            if (result.status === true) {
                models.revertObject (state.object, state.initial)
                backToList          ("copy", result.object)
            } else {
                if (result.message)
                    state.message = {kind: "error", message: result.message}
                state.errors = result.errors
            }
        }
        const revertFct = () => {
            models.revertObject (state.object, state.initial)
        }
        emit ("revert-function", revertFct)
        return {state, form, save, del, cancel, copy}
    },
    components: {Toast, Message, FormBuilder, FormToolbar, ConfirmDialog, ProgressSpinner}
}
</script>

<style>

</style>