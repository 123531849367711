<template>
    <div>
        <div style="margin-bottom:1em"> 
            <label style="margin-right: 1em" for="name">Name</label><InputText v-model="state.values.name" />
        </div>
        <ProjectSettingsModule v-for="module in state.addedModules" :key="module.muid"
            :header="module.headerText"
            :spec="module.spec"
            :moduleFlags="module.moduleFlags"
            :mhpFlags="module.mhpFlags"
            :values="state.values.settings [module.spec.MUID]"
            @remove-module="removeModule"
            @update:value="setNewValue"
        />
        <div style="margin-top: 1em">
            <SplitButton label="Save" icon="fa-solid fa-save" class="p-mb-2"  :model="splitActions" @click="saveSettings" />&nbsp;
            <Button label="Cancel" icon="fa-solid fa-times" class="p-button-warning p-mb-2" @click="cancel" />
        </div>
    </div>
</template>

<script>
import { reactive, computed, onMounted }    from "vue"
import ProjectSettingsModule                from '@/components/isew/ProjectSettingsModule'
import SplitButton                          from 'primevue/splitbutton'
import InputText                            from 'primevue/inputtext'
import models                               from '@/api/models'

export default {
    props: {
        puh:        {type: Number,  required: true},
        modules:    {type: Array,   required: true},
        object:     {type: Object,  required: true},
    },
    emits: ["cancel"],
    setup (props, {emit}) {
        const state = reactive ({addedModules: [], values: {}
        })
        const _updateAddedModules = (rdata, module, sort = false) => {
            let data = {name: module [1], pk: module [0],
                spec:           rdata.moduleSpec,
                mhpFlags:       rdata.mhpFlags,
                moduleFlags:    rdata.moduleFlags,
                headerText:     rdata.moduleSpec.moduleName + " (" + rdata.moduleSpec.MUID + ")",
            }
            if (!state.values.settings) state.values.settings = {}
            state.values.settings [rdata.moduleSpec.MUID] = rdata.values
            state.addedModules.push (data)
            if (sort)
                state.addedModules.sort ((l, r) => l.headerText < r.headerText)
        }
        const splitActions = computed (() => {
            let splitActions = []
            let am = {}
            state.addedModules.forEach (mod => {
                am [mod.pk] = mod
            })
            props.modules.forEach (module => {
                const mod = am [module [0]]
                if (mod == undefined) {
                    splitActions.push ({ label: module [1],
                        command: async () => {
                            const resp = await models.loadProjectSettingsForModule (props.puh, props.object.pk, module [0])
                            _updateAddedModules (resp.data, module)
                        }
                    })
                }
            })
            return splitActions
        })
        const removeModule = ((moduleId) => {
            const idx = state.addedModules.findIndex ((m) => m.pk == moduleId)
            if (idx >= 0) {
                state.addedModules.splice (idx, 1)
            }
        })
        onMounted ( async () => {
            const r = await models.loadProjectSettings(props.puh, props.object.pk)
            const data = r.data
            state.values = data.values
            for (const mod of data.modules) {
                const r = await models.loadProjectSettingsForModule (props.puh, props.object.pk, mod [0])
                _updateAddedModules (r.data, mod)
            }
        })
        const setNewValue = ({MUID, value, attr, parentAttr}) => {
            let values = state.values.settings [MUID]
            if (parentAttr != undefined) {
                values = values [parentAttr]
            }
            values [attr] = value
        }
        const saveSettings = async () => {
            const resp = await models.saveProjectSettings (props.puh, props.object.pk, state.values)
            if (resp.data.status)
                emit ("cancel")
        }
        const cancel = () => {                 
            emit ("cancel")
        }
        return {state, splitActions, removeModule, saveSettings, cancel,
            setNewValue
        }
    },
    components: {SplitButton, ProjectSettingsModule, InputText}
}
</script>

<style>

</style>