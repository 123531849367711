<template>
    <Card>
        <template #title>
            Download a translation update package for the iSEW display
        </template>
        <template #content>
            <div class="field-checkbox">            
                <CheckBox name="kind" v-model="downloads" value="M" />
                <label>Model Translation (Module names, Parameter names, ...)</label>
            </div>
            <div class="field-checkbox">            
                <CheckBox name="kind" v-model="downloads" value="A" />
                <label>Static Application Strings</label>
            </div>
            <div class="field-checkbox">            
                <CheckBox name="kind" v-model="downloads" value="S" />
                <label>Dynamic Application strings</label>
            </div>
            <div class="field-checkbox">            
                <CheckBox name="kind" v-model="downloads" value="L" />
                <label>Live screen</label>
            </div>
            <Button icon="pi pi-download" label="Download selected files" @click="download" />
        </template>
    </Card>
</template>

<script>
//import { useRouter }            from 'vue-router'
import { ref }                          from 'vue'
import { useToast }                     from 'primevue/usetoast'
import Card                             from 'primevue/card'
import CheckBox                         from 'primevue/checkbox'
import translation                      from "@/api/translation"

export default {
    components: {Card, CheckBox},
    setup () {
        const downloads = ref (['M', 'S', 'L', 'A'])
		const toast = useToast()
        const done = (event) => {
            let resp = JSON.parse (event.xhr.responseText)
            toast.add({severity: 'info', 
                summary: 'Success', 
                detail: `Keep ${resp.keep}, created ${resp.created}, deleted ${resp.deleted}` , life: 3000})
        }
        const download = async () => {
            await translation.downloadTranslations (downloads.value)
        }
        return {done, downloads, download}
    }
}
</script>

<style lang="scss" scoped>
    .field-checkbox label {
        margin-left: 0.4rem;
    }
    .p-button {
        margin-top: 1rem;
    }
    .field-checkbox {
        margin: 0.2rem;
    }
</style>