<template>
    <Card>
        <template #title>
            Download a zip file for a data center update
        </template>
        <template #subtitle>
            Please select the files/folder you wnat to include in the zip file
        </template>
        <template #content>
            <TreeTable
                :value="folders"
                selectionMode="checkbox" v-model:selectionKeys="selected"
                :expandedKeys="expandedKeys"
            >
                <Column header="Name" :expander="true" headerStyle="width: 50%">
                    <template #body="{node}">
                        <i :class="iconForIcon(node.data.icon)" />
                        {{ node.data.title || node.data.name }}
                    </template>
                </Column>
                <Column header="Info">
                    <template #body="{node}">
                        <span v-if="node.data.duration">
                            Duration: {{ node.data.duration / 100}} s
                        </span>
                        <span v-else-if="node.data.image">
                            Spare Parts: {{ node.data.spare_part.length}}
                        </span>
                    </template>
                </Column>
            </TreeTable>
            <Button icon="pi pi-download" label="Download selected files" @click="download" />
        </template>
    </Card>
</template>

<script>
import { onBeforeMount, ref }           from 'vue'
import Card                             from 'primevue/card'
import TreeTable                        from 'primevue/treetable'
import Column                           from 'primevue/column'
import DC                               from "@/api/data_center"

export default {
    components: {Card, TreeTable, Column},
    setup () {
        const folders      = ref ([{key: "root", data: {name: "Root", icon: "images/folder-tree-24.png"}, children: []}])
        const selected     = ref ({})
        const expandedKeys = ref ({})
        const expandNode = (node) => {
            if (node.children && node.children.length) {
                expandedKeys.value [node.key] = true

                for (let child of node.children) {
                    expandNode (child)
                }
            }
        }        
        onBeforeMount (async () => {
            const r = await DC.getFolderInfo (undefined, "1")
            folders.value [0].children = r.nodes
            for (let node of folders.value) {
                expandNode (node)
            }
        })
        const iconForIcon = (img) => {
          for (let e of DC.DC_IMAGE_CHOICES) {
            if (e.value == img) return `fa-duotone ${e.icon}` 
          }
          return "fa-duotone fa-question"
        }
        const download = async () => {
            let idList = []
            for (const k in selected.value) {
                idList.push (k)
            }
            console.log (idList)
            await DC.downloadFiles (idList)
        }
        return {folders, selected, expandedKeys, download, iconForIcon}
    }
}
</script>

<style>

</style>