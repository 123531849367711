<template>
	<div class="layout-topbar">
		<button class="p-link layout-menu-button" @click="onMenuToggle">
			<span class="fa-duotone fa-bars"></span>
		</button>
		<div class="layout-topbar-icons">
			<!--
			<span class="layout-topbar-search">
				<InputText type="text" placeholder="Search" />
				<span class="layout-topbar-search-icon fa-solid fa-search"></span>
			</span>
			<button class="p-link">
				<span class="layout-topbar-item-text">User</span>
				<span class="layout-topbar-icon fa-solid fa-user"></span>
			</button>
			-->
		</div>
	</div>
</template>

<script>
//import InputText    from "primevue/inputtext"

export default {
	//components: { InputText },
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        }
    }
}
</script>