<template>
    <Panel :header="header" :toggleable="true" :collapsed="true">
        <template #icons>
            <button class="p-panel-header-icon p-link p-mr-2" @click="$emit('remove-module', module [0])">
                <span class="fa-solid fa-trash"></span>
            </button>
        </template>
        <Card style="margin-bottom: 2em">
            <template #title>
                Module Settings
            </template>            
            <template #content>
                <div class="p-grid">
                    <div class="p-col-3" style="margin-top: 0.5em">
                        Default Visibility: <span class="p-text-bold">{{ dg2Text (spec.display_group) }}</span>
                    </div>
                    <div class="p-col-3">
                        New Visibility
                        <Dropdown :modelValue="values.display_group" 
                            @update:modelValue="updateValue($event,'display_group')"
                            :options="display_groups" optionLabel="label" optionValue="value" 
                        />
                    </div>
                    <div class="p-col">
                        Flags
                        <MultiSelect :modelValue="values.flags"
                            @update:modelValue="updateValue($event,'flags')"
                            :options="moduleFlags" optionLabel="label"
                        />
                    </div>
                </div>
            </template>            
        </Card>
        <DataTable :value="spec.parameters">
            <Column header="Parameter" field="name">
            </Column>
            <Column header="Defaul Settings">
                <template #body="props">
                    <div class="old_vis_list"><span>Visibility:</span><span>{{ vis2Text (props.data.visibility) }}</span></div>
                    <div class="old_vis_list"><span>Writable at:</span><span>{{ wri2Text (props.data.writable_at) }}</span></div>
                </template>
            </Column>
            <Column header="New Visibility" headerStyle="width:11em">
                <template #body="props">
                    <Dropdown :modelValue="values[props.data.puid].visibility" 
                        @update:modelValue="updateValue($event,'visibility', props.data.puid)"
                        :options="visibilities" optionLabel="label" optionValue="value" 
                    />
                </template>
            </Column>
            <Column header="New Writable At" headerStyle="width:11em">
                <template #body="props">
                    <Dropdown :modelValue="values[props.data.puid].writable_at" 
                        @update:modelValue="updateValue($event,'writable_at', props.data.puid)"
                        :options="writable_ats" optionLabel="label" optionValue="value" 
                    />
                </template>
            </Column>
            <Column header="Flags" headerStyle="width:30em">
                <template #body="props">
                    <MultiSelect :modelValue="values[props.data.puid].flags" 
                        @update:modelValue="updateValue($event,'flags', props.data.puid)"
                        :options="mhpFlags" optionLabel="label"
                    />
                </template>
            </Column>
        </DataTable>
    </Panel>   
</template>

<script>
import Card                                 from 'primevue/card';
import Panel                                from 'primevue/panel';
import MultiSelect                          from 'primevue/multiselect'
import DataTable                            from 'primevue/datatable'
import Column                               from 'primevue/column'
import Dropdown                             from 'primevue/dropdown';

const Display_Group = {
    0x00: "Hidden",
    0x01: "Operator",
    0x02: "Supervisor",
    0x03: "Technician",
    0x04: "Dealer",
    0x05: "Supplier",
    0xFF: "unchanged"
}
const Visibility_Level = {
    0x00: "Hidden",
    0xEA: "Operator",
    0xE8: "Supervisor",
    0xE0: "Technician",
    0xC0: "Dealer",
    0x80: "Supplier",
    0xFF: "unchanged"
}
const Writeable_Level = {
    0xD5: "Operator",
    0xD4: "Supervisor",
    0xD0: "Technician",
    0xC0: "Dealer",
    0x80: "Supplier",
    0xFF: "unchanged"
}

export default {
    props: {
        header:         {type: String,  required: true},
        values:         {type: Object,  required: true},
        spec:           {type: Object,  required: true},
        moduleFlags:    {type: Object,  required: true},
        mhpFlags:       {type: Object,  required: true},
    },
    emits: ["remove-module", "update:value"],
    setup (props, {emit}) {
        const visibilities = []
        for (const [key, value] of Object.entries (Visibility_Level)) {
            visibilities.push ({label: value, value: Number (key)})
        }
        const writable_ats = []
        for (const [key, value] of Object.entries (Writeable_Level)) {
            writable_ats.push ({label: value, value: Number(key)})
        }
        const display_groups = []
        for (const [key, value] of Object.entries (Display_Group)) {
            display_groups.push ({label: value, value: Number(key)})
        }
        const vis2Text = (v => Visibility_Level [v])
        const wri2Text = (v => Writeable_Level  [v])
        const dg2Text  = (v => Display_Group    [v])
        const updateValue = (value, attr, parentAttr) => {
            emit ("update:value", {MUID: props.spec.MUID, value, attr, parentAttr})
        }
        return {visibilities, writable_ats, display_groups, vis2Text, wri2Text, dg2Text, updateValue}
    },
    components: {Panel, DataTable, Column, Dropdown, MultiSelect, Card}
}
</script>

<style scoped>
     div.old_vis_list span:first-child {
         width: 7em;
         display: inline-block;
     }
     div.old_vis_list span:last-child {
         font-weight: bold;
     }
</style>