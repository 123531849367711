<template>
  <form class="formgrid grid">
    <div class="field col-12 md:col-6">
        <label for="title">Title</label>
        <InputText style="width: 40rem"  autofocus
            v-model="localObject.title"
        />
    </div>
    <div class="field col-12 md:col-6">
        <label for="name">Name</label>
        <InputText style="width: 40rem"
            v-model="localObject.name"
        />
    </div>
    <div class="field col-12">
        <label for="icon">Icon</label>
      <Dropdown style="width: 40rem"
          optionLabel="name" optionValue="value" :options="DC_IMAGE_CHOICES"
          v-model="localObject.icon"
      >
        <template #value="{ value } ">
          <div class="p-dropdown-car-option">
            <i :class="iconForIcon(value)" />&nbsp;
            <span>{{ nameForIcon (value) }}</span>
          </div>
        </template>
        <template #option="{ option } ">
          <div class="p-dropdown-car-option">
            <i :class="iconForIcon(option.value)" />&nbsp;
            <span>{{option.name}}</span>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="field col-12">
        <label for="visibility">Visibility</label>
        <Dropdown style="width: 40rem"
            optionLabel="name" optionValue="value" :options="DC_VISIBILITY"
            v-model="localObject.visibility"
        />
    </div>
    <div class="field col-12">
        <label for="parent">Parent</label>
        <TreeSelect v-model="localObject.parent_id" style="width: 40rem"
            :options="folders"
            optionLabel="title" optionValue="id" optionGroupLabel="title"
            :optionGroupChildren="['subfolders']"
            />
    </div>
    <div style="text-align: center; margin-top:1em">
        <Button label="Save" @click="save" />&nbsp;
        <Button class="p-button-warning" label="Cancel" @click="close"/>
    </div>
  </form>
</template>

<script>
import { reactive, onBeforeMount, ref } from 'vue'
import InputText                        from 'primevue/inputtext'
import Dropdown                         from 'primevue/dropdown'
import TreeSelect                       from 'primevue/treeselect'
import DC                               from "@/api/data_center"

const DC_IMAGE_CHOICES = 
    [ {value: "images/folder-tree-24.png",         name: "Tree",        icon: "fa-folder-open"}
    , {value: "images/rectangle-list-24.png",      name: "List",        icon: "fa-rectangle-list"}
    , {value: "images/screwdriver-wrench-24.png",  name: "Spare Part",  icon: "fa-screwdriver-wrench"}
    , {value: "images/video-24.png",               name: "Video",       icon: "fa-video"}
    ]
const DC_VISIBILITY = 
    [ {value: 0x02, name: "Operator"}
    , {value: 0x08, name: "Supervisor"}
    , {value: 0x20, name: "Technician"}
    , {value: 0x40, name: "Dealer"}
    , {value: 0x80, name: "Supplier"}
    ]
export default {
    props: {
        object: { type: Object, required: true},
        parent: { type: Object, required: true},
    },
    emits: ["update:object", "objectSaved", "close"],
    components: {InputText, Dropdown, TreeSelect},
    setup (props, { emit }) {
        const folders    = ref ([])
        const localObject = reactive ({...props.object})
        const pid = localObject.parent_id
        if (!localObject.icon)
            localObject.icon = DC_IMAGE_CHOICES [0].value
        localObject.parent_id = {[pid] : true}
        onBeforeMount (async () => {
            const r = await DC.getAssets ("folders", [localObject.id])
            folders.value = r.folders
        })
        const close = () => {
            emit ("close")
        }
        const save = async () => {
            localObject.parent_id = Object.keys (localObject.parent_id) [0]
            const r = await DC.saveFolder (localObject)
            emit  ("update:object", localObject)
            emit  ("objectSaved",   {node: r.data.node, old_parent: pid})
            close ()
        }
        const iconForIcon = (img) => {
          for (let e of DC_IMAGE_CHOICES) {
            if (e.value == img) return `fa-duotone ${e.icon}` 
          }
          return "fa-duotone fa-question"
        }
        const nameForIcon = (img) => {
          for (let e of DC_IMAGE_CHOICES) {
            if (e.value == img) return e.name
          }
          return img
        }
        return {localObject, DC_IMAGE_CHOICES, DC_VISIBILITY, save, close,
            iconForIcon, nameForIcon,
            folders}
    }
}
</script>

<style scoped>
    label {
        display:    inline-block;
        width:      20rem;
    }
</style>