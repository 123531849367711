<template>
    <Toolbar>
        <template #start>
            <Button label="Cancel" icon="fa-solid fa-xmark" class="p-button-secondary p-mr-2"
                @click="$emit('cancel')"
            />
            <Button label="Delete" icon="fa-solid fa-trash" class="p-button-danger p-mr-2"
                @click="$emit('delete')"
                v-if="modelDelete && buttons.indexOf ('d') >= 0"
            />
        </template>
        <template #end>
            <Button label="Save as copy" icon="fa-solid fa-clone" class="p-button-warning p-mr-2"
                @click="$emit('copy')"
                v-if="modelCopy && buttons.indexOf ('c') >= 0"
            />
            <Button label="Save" icon="fa-solid fa-save" class="p-button-success p-mr-2"
                @click="$emit('save')"
            />
        </template>
    </Toolbar>
</template>

<script>
import { computed }             from 'vue'
import Toolbar                  from 'primevue/toolbar';
import auth                     from "@/store/auth"
export default {
    emits: ["save", "cancel", "delete", "copy"],
    props: { hasErrors: {type: Boolean, default: false}
           , buttons:   {type: String, default: "dc"}
           },
    setup () {
        const aState = auth.getAuthState ()
        const modelDelete = computed (() => aState.user.groups.MODEL_EDIT == true)
        const modelCopy   = computed (() => aState.user.groups.MODEL_EDIT == true)
        return {modelDelete, modelCopy}
    },
    components: {Toolbar},
}
</script>

<style>

</style>