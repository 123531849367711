<template>
<div>
  <div class="field col-12 md:col-6">
      <label for="name">Name</label>
      <InputText style="width: 40rem" ref="name" autofocus
          v-model="localObject.name"
      />
  </div>
  <div class="field col-12 md:col-6">
      <label for="image">Image File</label>
      <Dropdown style="width: 40rem"
          v-model="localObject.image" :options="images" optionValue="file_name" optionLabel="file_name"
      />
      <FileUpload 
          mode="basic" 
          name="video" 
          accept="image/*" 
          chooseLabel="Upload a new image"
          url="/api/v2/data-center/spare-part-image/upload/"
          :maxFileSize="1000000"
          auto
          @progress="uploadProgress"
          @upload="uploadComplete"
          @before-upload="startUpload"
          style="margin-left: 20rem"
          />
      <ProgressBar :value="fileupload.progress" 
        v-if="fileupload.inProgress"
      />

  </div>
  <div class="field col-12">
      <label for="icon">Icon</label>
      <Dropdown style="width: 40rem"
          optionLabel="name" optionValue="value" :options="DC_IMAGE_CHOICES"
          v-model="localObject.icon"
      >
        <template #value="{ value } ">
          <div class="p-dropdown-car-option">
            <i :class="iconForIcon(value)" />&nbsp;
            <span>{{ nameForIcon (value) }}</span>
          </div>
        </template>
        <template #option="{ option } ">
          <div class="p-dropdown-car-option">
            <i :class="iconForIcon(option.value)" />&nbsp;
            <span>{{option.name}}</span>
          </div>
        </template>
      </Dropdown>
  </div>
  <div class="field col-12">
      <label for="parent">Folder</label>
      <TreeSelect v-model="localObject.folder_id" style="width: 40rem"
          :options="folders"
          optionLabel="title" optionValue="id" optionGroupLabel="title"
          :optionGroupChildren="['subfolders']"
          />
  </div>
  <SparePartHandling 
    v-model:sparePart="localObject.spare_part"
    :sparePartImage="localObject.id"
    :image="localObject.image"
  />
  <div style="text-align: center; margin-top:1em">
      <Button label="Save" @click="save" :disabled="!localObject.image"/>&nbsp;
      <Button class="p-button-warning" label="Cancel" @click="close"/>
  </div>
</div>
</template>

<script>
import { reactive, onBeforeMount, ref } from 'vue'
import InputText                        from 'primevue/inputtext'
import FileUpload                       from 'primevue/fileupload'
import ProgressBar                      from 'primevue/progressbar'
import Dropdown                         from 'primevue/dropdown'
import TreeSelect                       from 'primevue/treeselect'
import SparePartHandling                from './sparePartHandling.vue'
import DC                               from "@/api/data_center"

const DC_IMAGE_CHOICES = 
    [ {value: "images/folder-tree-24.png",         name: "Tree",        icon: "fa-folder-open"}
    , {value: "images/rectangle-list-24.png",      name: "List",        icon: "fa-rectangle-list"}
    , {value: "images/screwdriver-wrench-24.png",  name: "Spare Part",  icon: "fa-screwdriver-wrench"}
    , {value: "images/video-24.png",               name: "Video",       icon: "fa-video"}
    ]

export default {
    props: {
        object: { type: Object, required: true},
        parent: { type: Object, required: true},
    },
    emits: ["update:object", "objectSaved", "close"],
    components: {InputText, Dropdown, FileUpload, ProgressBar, TreeSelect, SparePartHandling},
    setup (props, { emit }) {
        const name        = ref(null)
        const localObject = reactive ({...props.object})
        const fileupload  = reactive ({inProgress: false, progress: 0})
        const folders     = ref ([])
        const images      = ref ([])
        const fid         = localObject.folder_id
        if (!localObject.icon)
            localObject.icon = DC_IMAGE_CHOICES [2].value
        localObject.folder_id = {[fid] : true}
        const close = () => {
            emit ("close")
        }
        const save = async () => {
            localObject.folder_id = Object.keys (localObject.folder_id) [0]
            const r = await DC.saveSparePartImage (localObject)
            emit  ("update:object", localObject)
            emit  ("objectSaved",   {node: r.data.node, old_parent: fid})
            close ()
        }
        onBeforeMount (async () => {
            const r       = await DC.getAssets ("spare-part-images")
            images.value  = r.assets
            folders.value = r.folders
        })
        const startUpload = () => {
            fileupload.inProgress    = true
        }
        const uploadProgress = ({progress}) => {
            fileupload.progress = progress
        }
        const uploadComplete = ({files}) => {
            if (! localObject.name) {
                localObject.name     = files [0].name
            }
            localObject.image        = files [0].name
            fileupload.inProgress    = false
            name.value.$el.focus  ()
            name.value.$el.select ()
        }
        const iconForIcon = (img) => {
          for (let e of DC_IMAGE_CHOICES) {
            if (e.value == img) return `fa-duotone ${e.icon}` 
          }
          return "fa-duotone fa-question"
        }
        const nameForIcon = (img) => {
          for (let e of DC_IMAGE_CHOICES) {
            if (e.value == img) return e.name
          }
          return img
        }

        return {localObject, images, folders, DC_IMAGE_CHOICES, save, close, 
            fileupload, startUpload, uploadProgress, uploadComplete,
            name,
            iconForIcon, nameForIcon,
            }
    }
}
</script>

<style scoped>
    label {
        display:    inline-block;
        width:      20rem;
    }
</style>