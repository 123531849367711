<template>
    <Accordion :activeIndex="activeIndex">
        <AccordionTab>
            <template #header>
                <Button class="p-button-rounded p-button-info"
                        style="margin-right: 10px;"
                        @click.stop="addNew"
                        icon="fa-solid fa-plus"
                />
                {{ header }} ({{ objects.length }})
            </template>
            <component :is="inlineListComponent"
                :objects="objects"
                :listView="listView"
                :model="model"
                :reorderSpec="reorderSpec"
                :formName="formName"
                ref="objectList"
                :errors="errors"
                :showButtons="toolbar"
                filterDisplay=""
                :pagination="false"
                v-model:editingRows="state.editingRows"
                @reorder-row="forwardEvent ('reorder-row', $event)"
                @remove-row="forwardEvent ('remove-row', $event)"
                @change-row="forwardEvent ('change-row', $event)"
                @add-row="addRow"
            />
        </AccordionTab>
    </Accordion>
</template>

<script>
import { reactive }         from 'vue'
import models               from '@/store/models'
import Button               from 'primevue/button';
import Accordion            from 'primevue/accordion';
import AccordionTab         from 'primevue/accordiontab';
import ObjectListEditor     from "./ObjectListEditor"
import ModuleHasParameter   from "./ModuleHasParameter"

export default {
    name:           "InlineField",
    components:     {Accordion, AccordionTab, ObjectListEditor, ModuleHasParameter, Button},// eslint-disable-line vue/no-unused-components
    emits: ["reorder-row", "remove-row", "add-row", "change-row"],
    props: {
        header:      {type: String, required: true},
        model:       {type: Object, required: true},
        formName:    {type: String, default: "default"},
        listView:    {type: Object,  required: true},
        objects:     {type: Array,  required: true},
        reorderSpec: {type: Object},
        errors:      {type: Array},
        toolbar:     {type: String, default: "bottom"},
        inlineListComponent: {type: String, default: "ObjectListEditor"},
    },
    setup (props, {emit}) {
        const state = reactive ({ editingRows: []})
        const forwardEvent = (eventName, data) => {
            emit (eventName, data)
        }
        const addNew = async () => {
            const obj = await models.getObject (props.model.name, 0, "true")
            state.editingRows = [...state.editingRows, obj]
            emit ("add-row", {object: obj})
        }
        const addRow = (event) => {
            state.editingRows = [...state.editingRows, event.object]
            forwardEvent ('add-row', event)
        }
        const activeIndex = props.objects.length > 0 ? 0 :  -1
        return {state, forwardEvent, addNew, addRow, activeIndex}
    }
}
</script>

<style>
    .align-right {
         margin-left: auto;
    }
</style>