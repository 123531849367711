import session from './session'
export default {
    async loadLicenceKeys (rootClass, id) {
        const resp = await session.get (`licence-key-generation/${rootClass}/${id}/`)
        return resp.data
    },
    async loadFeatures() {
        const resp = await session.get (`features/`)
        return resp.data
    },
    async loadGenerationDetails (pk) {
        const resp = await session.get (`licence-key-generation/${pk}/`)
        return resp.data
    },
    async newGeneration (rootClass, id, key_type, features) {
        const resp = await session.put (`licence-key-generation/`, {rootClass, id, key_type, features})
        return resp.data
    },
    async generateKey (rootClass, id, ecuId) {
        const resp = await session.post (`licence-key-generation/`, {rootClass, id, ecu_id: ecuId})
        return resp.data
    },
    async getKey (rootClass, id, ecuId) {
        const resp = await session.get (`export-licence-key/`,
            { params: {rootClass, id, ecu_id: ecuId}, responseType: "blob"})
        const h = resp.headers ["content-disposition"]
        const fileName = /filename=(.+)$/.exec (h) [1]
        console.log (fileName)
        const downloadUrl = window.URL.createObjectURL (new Blob ([resp.data]))
        const link = document.createElement ('a')
        link.href  = downloadUrl
        link.setAttribute ("download", fileName)
        document.body.appendChild (link)
        link.click                ()
        link.remove               ()
        return resp.data
    }
}
