<template>
    <div class="p-grid p-justify-center p-align-center full-height">
        <div class="p-col-3">
        <Card>
            <template #title>
                Specify the new password
            </template>
            <template #content>
                <template v-if="regState.resetState == 'processing'">loading...</template>
                <template v-else-if="regState.resetState == 'input'">
                    <form @submit.prevent="submit" class="p-fluid">
                        <ErrorList title="A error occured while processing your request." :errors="regState.errors.non_field_errors"/>
                        <FormField field-name="old_password" label="Current password" :errors="regState.errors.old_password">
                            <Password v-model="inputs.old_password" toggleMask />
                        </FormField>
                        <FormField field-name="new_password1" label="New Password" :errors="regState.errors.new_password1">
                            <Password v-model="inputs.new_password1" toggleMask />
                        </FormField>
                        <FormField field-name="new_password1" label="Confirm" :errors="regState.errors.new_password2">
                            <Password v-model="inputs.new_password2"  toggleMask :feedback="false"/>
                        </FormField>
                    </form>
                    <Button @click="changePassword" label="Reset Password" icon="fa-solid fa-check"/>
                </template>
                <template v-else>
                    <div>
                        Your password has been reset.
                    </div>
                </template>
            </template>
        </Card>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import Auth         from "@/store/auth"
import Button       from "primevue/button"
import Card         from "primevue/card"
import Password     from "primevue/password"
import FormField    from "@/components/FormField"
import ErrorList    from "@/components/ErrorList.vue"

export default {
    setup () {
        const inputs = reactive ({
            new_password1: "",
            new_password2: "",
            old_password:  ""
        })
        const changePassword = async () => {
            await Auth.changePassword (inputs)
        }
        return {inputs, regState: Auth.getRegState (), changePassword}
    },
    components: {Card, Button, Password, ErrorList, FormField},
}

</script>

