<template>
    <div>
        <div v-if="state.message">
            <Message :severity="state.message.kind"
                     :closable="state.message.kind != 'error'"
            >{{ state.message.message }}</Message>
        </div>
        <div v-else-if="!state.loading">
            <ListView :model="state.model" view="listView" />
        </div>
        <div v-else>
            Loading...
        </div>
    </div>
</template>

<script>
import { reactive }         from 'vue'
import models               from '@/store/models'
import ListView             from "@/components/models/ListView"
import Message              from 'primevue/message'

export default {
    props: { "modelName" : {
        require: true,
        type:    String
        }
    },
    setup (props) {
        const state = reactive ({
            loading : true,
            model:    null,
            message:  null
        })
        models.setupModelLoading (props, state)
        return { state }
    },
    components: {Message, ListView},
}
</script>
