<template>
    <div>
        <DataTable ref="objectList" class="p-datatable-striped p-datatable-sm"
            :value="objects"
            :dataKey="model.form.pk"
            autoLayout
            :loading="loading"

            :paginator="pagination"
            paginatorPosition="both"
            :rowsPerPageOptions="[10,25,50,100]"
            :rows="25"

            :filters="state.filters"
            :filterDisplay="filterDisplay"

            rowHover
            :sortField="sortOrder.field"
            :sortOrder="sortOrder.order"

            editMode="row"
            :editingRows="editingRows"
            @update:editingRows="updateEditingRows"
            @row-reorder="onRowReorder"

            @row-edit-init="saveRowState"
            @row-edit-cancel="restoreRowState"
            @row-edit-save="saveRow"

            v-model:expandedRows="state.expandedRows"
        >
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" v-if="reorderSpec.enabled" />
            <Column v-for="col of columns"
                :field="col.field"
                filterField="name"
                :header="col.label"
                :key="col.field"
                :sortable="listView.sortable.indexOf (col.field) >= 0"
                >
                <template #body="props">
                    <ColumnValueorError :col="col" :index="props.index"
                                        :data="props.data"
                                        :errors="errors" />
                </template>
                <template #filter v-if="listView.filter[col.field]">
                    <InputText type="text" v-model="state.filters[col.field].value" class="p-column-filter" />
                </template>
                <template #editor="props">
                    <template v-if="col.type =='foreign-key'">
                        <ForeignKeyInput :field="col" v-model="props.data[col.field]" :id="col.field" />
                    </template>
                    <template v-else-if="col.type =='integer'">
                        <InputNumber v-model="props.data[col.field]"
                            showButtons
                            :min="col.minValue" :max="col.maxValue"
                        />
                    </template>
                    <template v-else-if="col.type =='select'">
                        <Dropdown v-model="props.data[col.field]"
                            :options="col.choices"
                            optionLabel="label"
                            optionValue="value"
                            />
                    </template>
                    <template v-else>
                        <InputText v-model="props.data[props.column.props.field]" />
                    </template>
                </template>
            </Column>
            <Column headerStyle="width:80px;" bodyStyle="text-align: right; padding-right: 0px">
                <template #body="slotProps">
                    <Button icon="pi pi-copy"     class="p-button-rounded p-button-text p-button-warning"     @click="addNew ({source: slotProps.data})" />
                    <Button icon="pi pi-trash"    class="p-button-rounded p-button-text p-button-danger"      @click="askDeleteConfirm(slotProps.data)" />
                </template>
            </Column>
            <Column class="edit-button" :rowEditor="true" headerStyle="width: 40px" bodyStyle="text-align:left; padding-left: 0px" />
            <template #header v-if="showButtons == 'both' || showButtons == 'top'">
                <Toolbar class="p-mb-4">
                    <template #right>
                        <Button label="New"    icon="fas fa-plus" class="p-button-success p-mr-2" @click="addNew" />
                    </template>
                </Toolbar>
            </template>
            <template #footer v-if="showButtons == 'both' || showButtons == 'bottom'">
                <Toolbar class="p-mb-4">
                    <template #right>
                        <Button label="New"    icon="fas fa-plus" class="p-button-success p-mr-2" @click="addNew" />
                    </template>
                </Toolbar>
            </template>
            <template #expansion="props">
                hasConiditions {{props}}
            </template>
        </DataTable>
    </div>
</template>

<script>
import { reactive, computed, unref } from 'vue'
import models               from '@/store/models'
import DataTable            from 'primevue/datatable'
import Column               from 'primevue/column'
import Toolbar              from 'primevue/toolbar';
import { useConfirm }       from "primevue/useconfirm";
import Dropdown             from 'primevue/dropdown'        // eslint-disable-line no-unused-vars
import InputText            from 'primevue/inputtext'
import InputNumber          from 'primevue/inputnumber'
import ForeignKeyInput      from '@/components/models/ForeignKeyInput'  // eslint-disable-line no-unused-vars
import ColumnValueorError   from './ColumnValueorError'

export default {
    components: {DataTable, Column, InputText, Toolbar, ForeignKeyInput, Dropdown, InputNumber, ColumnValueorError},
    props: {
        objects:        {type: Array,   required: true},
        editingRows:    {type: Array,   required: true},
        listView:       {type: Object,  required: true},
        model:          {type: Object,  required: true},
        reorderSpec:    {type: Object,  default() { return {enabled: false}}},
        formName:       {type: String,  default: "default"},
        showButtons:    {type: String,  default: ""},
        errors:         {type: Array,  default() { return []}},
        loading:        {type: Boolean, default: false},
        inlineEdit:     {type: Boolean, default: false},
        pagination:     {type: Boolean, default: true},
        filterDisplay:  {type: String,  default: "row"},
    },
    emits: ["reorder-row", "remove-row", "add-row", "change-row", "update:editingRows"],
    setup (props, {emit}) {
        const state  = reactive ({
            filters: {},
            reoderAllowed: props.reorderSpec.enabled,
            savedRows: {},
            expandedRows: []
        })
        for (const [key, value] of Object.entries (props.listView.filter)) {
            state.filters [key] = {value: "", matchMode: value}
        }
        const columns = computed (() => {
            const result = []
            if (props.model) {
                props.listView.fields.forEach ((name) => {
                    const field = Object.assign ({}, props.model.fields [name])
                    result.push (field)
                })
            }
            return result
        })
        const confirm = useConfirm() // eslint-disable-line no-unused-vars
        const addNew = async (data) => {
            let id    = -1
            if ((data || {}).source) {
                id = data.source [props.model.form.pk]
            }
            const obj = await models.getObject (props.model.name, id, "true")
            emit ("add-row", {source: data.source, object: obj})
        }
        const askDeleteConfirm = (object) => {
            if (object [props.model.form.pk] < 0) {
                const idx = props.objects.indexOf (object)
                emit ("remove-row", idx)
            } else {
                confirm.require({
                    message: 'Are you sure you want to delete the linked object?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    acceptIcon: "pi pi-trash",
                    acceptLabel: "Yes",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        const idx = props.objects.indexOf (object)
                        emit ("remove-row", idx)
                    },
                });
            }
        }
        const sortOrder = {field: null, order: 1}
        if (props.listView.defaultSort) {
            sortOrder.field =  unref (props.listView.defaultSort)
            if (sortOrder.field [0] == "-") {
                sortOrder.field = sortOrder.field.substring (1)
                sortOrder.order = -1
            }
        }
        const onRowReorder = (event) => {
            emit ("reorder-row", event)
        }
        const updateEditingRows = (data) => {
            emit ("update:editingRows", data)
        }
        const saveRowState = (event) => {
            const obj = event.data
            const key = obj [props.model.form.pk]
            state.savedRows [key] = models.copyObject (obj, props.model.form.pk)
        }
        const restoreRowState = (event) => {
            const obj = event.data
            const key = obj [props.model.form.pk]
            const idx = props.objects.map (e => e.id).indexOf (key)
            models.revertObject (props.objects [idx], state.savedRows [key])
            delete state.savedRows [key]
        }
        const saveRow = (event) => {
            emit ("change-row", event)
        }
        return { state, columns, addNew
               , askDeleteConfirm, sortOrder, onRowReorder, updateEditingRows
               , saveRowState, restoreRowState, saveRow
               }
    }
}
</script>

<style>
    .edit-button button.p-row-editor-init.p-link {
        width:  33px !important;
        height: 33px !important;
    }
</style>