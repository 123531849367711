<template>
    <ConfirmDialog></ConfirmDialog>
    <Card>
        <template #title>
            Data Center Setup
        </template>
        <template #content>
            <TreeTable class="p-treetable-sm" 
                :loading="state.loading"
                :value="state.folders"
                :lazy="true"
                @nodeExpand="loadChildren"
            >
                <Column header="Name" field="name" :expander="true"
                        style="width:40%"
                >
                    <template #body={node}>
                        <i :class="nodeIcon(node)" />&nbsp;
                        <span v-if="node.data.title">{{ node.data.title }}</span>
                        <span v-else>{{node.data.name}}</span>
                    </template>
                </Column>
                <Column header="Details">
                    <template #body="{node}">
                        <template v-if="node.data.video">
                            {{node.data.video}}, {{ node.data.duration / 100 }}s
                        </template>
                        <template v-else-if="node.data.image">
                            {{ node.data.image }}
                        </template>
                        <template v-else-if="node.data.visibility">
                            {{ visibilityName (node) }}
                        </template>
                        <template v-else>
                            &nbsp;
                        </template>
                    </template>
                </Column>
                <Column header="">
                    <template #header>
                        <Button icon="fa-duotone fa-folder-plus"
                            class="p-button-rounded p-button-primary p-button-text"
                            @click="addSubfolder()"
                            />
                        
                    </template>
                    <template #body="{node}">
                        <Button icon="fa-duotone fa-pen-to-square"
                            class="p-button-rounded p-button-secondary p-button-text"
                            v-tooltip.top="'Edit'"
                            @click="editNode(node)"
                             />
                        <Button icon="fa-duotone fa-trash"
                            class="p-button-rounded p-button-danger p-button-text"
                            v-tooltip.top="'Delete'"
                            @click="deleteNode(node.data)"
                            />
                        <Button icon="fa-duotone fa-folder-plus"
                            class="p-button-rounded p-button-primary p-button-text"
                            v-tooltip.top="'Add subfolder'"
                            v-if="node.data.title != null"
                            @click="addSubfolder(node)"
                            />
                        <Button icon="fa-duotone fa-screwdriver-wrench"
                            class="p-button-rounded p-button-primary p-button-text"
                            v-tooltip.top="'Add new spart part'"
                            v-if="node.data.title != null"
                            @click="addSparePart(node)"
                            />
                        <Button icon="fa-duotone fa-video"
                            class="p-button-rounded p-button-primary p-button-text"
                            v-tooltip.top="'Add new video'"
                            v-if="node.data.title != null"
                            @click="addVideo(node)"
                            />
                    </template>
                </Column>
            </TreeTable>
        </template>
    </Card>
    <Dialog :modal="true"
        v-model:visible="state.dialog.visible"
        :header="state.dialog.header"
        >
        <component :is="state.dialog.mainComponent" v-bind="state.dialog.componentProps"
            @close="state.dialog.visible = false"
            @objectSaved="updateNode"
        ></component>
    </Dialog>
</template>

<script>
//import { useRouter }               from 'vue-router'
import { onBeforeMount, reactive, ref }  from 'vue'
import { useToast }                 from 'primevue/usetoast'
import { useConfirm }               from 'primevue/useconfirm'
import ConfirmDialog                from 'primevue/confirmdialog'
import Card                         from 'primevue/card'
import auth                         from "@/store/auth"
import DC                           from "@/api/data_center"
import TreeTable                    from 'primevue/treetable'
import Column                       from 'primevue/column'
import Dialog                       from 'primevue/dialog'
import ModifyFolder                 from '@/components/isew/data-center/modifyFolder'
import ModifyVideo                  from '@/components/isew/data-center/modifyVideo'
import ModifySparePart              from '@/components/isew/data-center/modifySparePart'

const iconMap = { 
    "images/folder-tree-24.png"       : "fa-folder-open", //"fa-list-tree",
    "images/rectangle-list-24.png"    : "fa-rectangle-list",
    "images/screwdriver-wrench-24.png": "fa-screwdriver-wrench",
    "images/video-24.png"             : "fa-video"
}

const VisibilityMap = 
    { 0x02: "Operator"
    , 0x08: "Supervisor"
    , 0x20: "Technician"
    , 0x40: "Dealer"
    , 0x80: "Supplier"
    }

export default {
    components: {Card, TreeTable, Column, Dialog, ConfirmDialog, ModifyFolder, ModifyVideo, ModifySparePart},
    setup () {
        const confirm = useConfirm();
        const toast   = useToast();        
        const nodes   = {null:{data:{title: "", id: null}}}
        const groups  = auth.getAuthState().user.groups
        const state   = reactive ({loading: true,
            folders: [],
            dialog: { header: "Dialog Header",
                create: false,
                mainComponent: "",
                componentProps: {},
                visible: false
            }
            })
        const addActions = ref ([
              { label:    "Add Subfolder"
              , icon:     "fa-duotone fa-folder-plus"
              }
            , { label:    "Add Spare Part"
              , icon:     "fa-duotone fa-screwdriver-wrench"
              }
            , { label:    "Add Video"
              , icon:     "fa-duotone fa-video"
              }
        ])
        const confirmDelete = async (node, children) => {
            let title = node.name
            let model
            if (node.title) {
                title = node.title
                model = "folder"
            } else if (node.video) {
                model = "video"
            } else if (node.image) {
                model = "spare-part-image"
            }
            confirm.require({
                message: `Are you sure you want to delete ${title}?`,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    await DC.deleteObject (model, node.id)
                    toast.add({severity:'info', summary:'Confirmed', detail:'Deleted', life: 3000});
                let idx = -1
                for (let i in children) {
                    if (children [i].data.id == node.id)
                        idx = i
                }
                children.splice (idx, 1)
                },
            });            
        }
        const addNodes = newNodes => {
            for (const n of newNodes) 
                if (n.data.title) {
                    nodes [n.data.id] = n
                    if (n.chilren == undefined)
                        n.children = []
                }
        }
        const loadFolder = async (parent) => {
            state.loading = true
            const r = await DC.getFolderInfo (parent)
            state.loading = false
            return r
        }
        onBeforeMount (async () => {
            const r = await loadFolder ()
            state.folders = r.nodes
            addNodes (state.folders)
        })
        const loadChildren = async (node) => {
            const r = await loadFolder (node.data.id)
            node.children = r.nodes
            addNodes (node.children)
        }
        const nodeIcon = (node) => {
            return "fa-duotone " + iconMap [node.data.icon];
        }
        const visibilityName = (node) => {
            return VisibilityMap [node.data.visibility]
        }
        const editNode = async (node) => {
            if (node.data.title) {
                const parent = nodes [node.data.parent_id].data
                state.dialog.visible        = true
                state.dialog.create         = false
                state.dialog.header         = "Edit subfolder"
                state.dialog.mainComponent  = "ModifyFolder"
                state.dialog.componentProps = {object: node.data, parent: parent}
            } else if (node.data.video){
                const parent = nodes [node.data.folder_id].data
                state.dialog.visible        = true
                state.dialog.create         = false
                state.dialog.header         = "Edit subfolder"
                state.dialog.mainComponent  = "ModifyVideo"
                state.dialog.componentProps = {object: node.data, parent: parent}
            } else if (node.data.image){
                const parent = nodes [node.data.folder_id].data
                state.dialog.visible        = true
                state.dialog.create         = false
                state.dialog.header         = "Edit subfolder"
                state.dialog.mainComponent  = "ModifySparePart"
                state.dialog.componentProps = {object: node.data, parent: parent}
            }
        }
        const deleteNode = async (node) => {
            let children = state.folders
            if (node.parent_id)
                children = nodes [node.parent_id].children
            await confirmDelete (node, children)
        }
        const addSubfolder = async (node) => {
            let parent = {"data": {"id" : null}}
            if (node) 
                parent = nodes [node.data.id]
            state.dialog.visible        = true
            state.dialog.create         = true
            state.dialog.header         = "Add a subfolder"
            state.dialog.mainComponent  = "ModifyFolder"
            state.dialog.componentProps = {object: {parent_id:parent.data ["id"]}, parent: parent.data}
        }
        const addVideo = async (node) => {
            const parent = nodes [node.data.id]
            state.dialog.visible        = true
            state.dialog.create         = true
            state.dialog.header         = "Add a new video"
            state.dialog.mainComponent  = "ModifyVideo"
            state.dialog.componentProps = {object: {folder_id:parent.data ["id"]}, parent: parent.data}
        }
        const addSparePart = async (node) => {
            const parent = nodes [node.data.id]
            state.dialog.visible        = true
            state.dialog.create         = true
            state.dialog.header         = "Add a new spart part list"
            state.dialog.mainComponent  = "ModifySparePart"
            state.dialog.componentProps = {object: {folder_id:parent.data ["id"]}, parent: parent.data}
        }
        const updateNode = ({node, old_parent}) => {
            let new_parent   = node.data.parent_id ||node.data.folder_id
            let children     = state.folders
            let old_children = state.folders
            if (old_parent)
                old_children = nodes [old_parent].children
            if (new_parent) {
                children     = nodes [new_parent].children
            }
            if (state.dialog.create) {
                children.push (node)
                if (node.data.title)
                    addNodes ([node])
            } else {
                if (old_parent != new_parent) { // node has been moved
                    // append it tp the new parent list
                    children.push (node)
                    // remove the new form the old children list
                    const idx = old_children.map ((e, i) => {
                        if (e.key == node.key) return i
                        return null
                    }).filter (i => i != null) [0]
                    old_children.splice (idx, 1)
                    if (node.data.title)
                        addNodes ([node])
                }
                for (let c of children) {
                    if (c.key == node.key) {
                        c.data          = node.data
                        addNodes ([node])
                    }
                }
            }
        }
        return {state, addActions, groups, loadChildren, nodeIcon, visibilityName,
            updateNode,
            editNode, deleteNode, addSparePart, addVideo, addSubfolder
        }
    }
}
</script>

<style>

</style>