<template>
	<div class="layout-profile" v-if="loggedIn ()">
		<div>
			<img src="assets/layout/images/profile.png" alt="" />
		</div>
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{ user.username }}
				<span v-if="fullName"> ({{ fullName}})</span>
			</span>
			<i class="fa-duotone fa-user-circle"></i>
		</button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button class="p-link">
					<i class="fa-solid fa-passport"></i><router-link :to="{ name: 'change_password'}"> Change Password</router-link>
				</button></li>
                <li><button class="p-link">
					<i class="fa-duotone fa-sign-out"></i><router-link :to="{ name: 'logout'}"> Logout</router-link>
				</button></li>
            </ul>
        </transition>
	</div>
	<div class="layout-profile" v-else>
		<button class="p-link">
			<i class="fa-duotone fa-sign-in"></i>
			<router-link :to="{ name: 'login'}"> Login</router-link>
		</button>
	</div>
</template>

<script>
import {ref, computed} from "vue"
import Auth  from "@/store/auth"

export default {
	setup () {
		const expanded = ref (false)
		const state    = Auth.getAuthState ()
		const onClick  = (evt) => {
			expanded.value = !expanded.value
			evt.preventDefault ()
		}
		const fullName = computed (() => {
			const fn = state.user.firstName + " " + state.user.lastName
			return fn.trim (" ")
		})
		return {expanded, onClick, loggedIn : Auth.isLoggedIn, user: state.user, fullName}
	}
}
</script>

<style scoped>
</style>