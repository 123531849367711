<template>
    <div class="p-grid p-justify-center p-align-center full-height">
        <Card class="p-col-3">
            <template #title>
                Reset Password
            </template>
            <template #content>
                <template v-if="regState.resetState == 'processing'">Processing. please wait...</template>
                <template v-else-if="regState.resetState == 'input'">
                    <form @submit.prevent="submit" class="p-fluid">
                        <ErrorList :errors="regState.errors.email" title="A error occured while processing your request." />
                        <FormField field-name="email" label="E-Mail" :errors="regState.errors.email">
                            <InputText v-model="inputs.email" type="text" id="email" />
                        </FormField>
                    </form>
                    <Button @click="resetPassword" icon="fa-duotone fa-envelope" label="Send instructions" />
                </template>
                <template v-else>
                    <div>
                        Check your inbox for a link to reset your password. If an email doesn't appear within a few
                        minutes, check your spam folder.
                    </div>
                    <router-link :to="{name: 'login'}">Return to login page</router-link>
                </template>
            </template>
        </Card>
    </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import Auth         from "@/store/auth"
import Button       from "primevue/button"
import Card         from "primevue/card"
import InputText    from "primevue/inputtext"
import ErrorList    from "@/components/ErrorList"
import FormField    from "@/components/FormField"

export default {
    setup() {
        const inputs = reactive({ email: ""})
        const resetPassword = async () => {
            await Auth.resetPassword (inputs)
        }
        onMounted(() => {
                inputs.email = ""
                Auth.getRegState ().resetState = "input"
                console.log ("MOUNTED")
            })
        return {inputs, regState: Auth.getRegState (), resetPassword}
    },
    components: {Card, Button, InputText, ErrorList, FormField},
}
</script>

<style lang="scss" scoped>
    .full-height {
        height: 99%;
    }
    button {
        margin-top:   0.5em;
        margin-right: 0.5em;
    }
</style>
