import session      from './session'

const DC_IMAGE_CHOICES = 
    [ {value: "images/folder-tree-24.png",         name: "Tree",        icon: "fa-folder-open"}
    , {value: "images/rectangle-list-24.png",      name: "List",        icon: "fa-rectangle-list"}
    , {value: "images/screwdriver-wrench-24.png",  name: "Spare Part",  icon: "fa-screwdriver-wrench"}
    , {value: "images/video-24.png",               name: "Video",       icon: "fa-video"}
    ]

const data_center = {
    async getFolderInfo (folder, recursive) {
        const r = await session.get (`data-center/folder/info/${folder}/`, {params: {recursive}})
        return r.data
    },
    async getAssets (kind, disabledFolders) {
        const r = await session.get (`data-center/asset/${kind}/`, {params: {disabledFolders}})
        return r.data
    },
    async saveFolder (data) {
        if (data.id)
            return await session.put (`data-center/folder/${data.id}/`, data)
        return await session.post    (`data-center/folder/`, data)
    },
    async saveVideo (data) {
        if (data.id)
            return await session.put (`data-center/video/${data.id}/`, data)
        return await session.post    (`data-center/video/`, data)
    },
    async saveSparePartImage (data) {
        if (data.id)
            return await session.put (`data-center/spare-part-image/${data.id}/`, data)
        return await session.post    (`data-center/spare-part-image/`, data)
    },
    async deleteObject (model, pk) {
        return await session.delete (`data-center/${model}/${pk}/`)
    },
    async downloadFiles_old (selected) {
        return await session.request ({ url: `data-center/download/` ,
                           method: "GET",
                           responseType: 'blob',
                        params: {selected}}).then((resp) => {
            const downloadUrl = window.URL.createObjectURL (new Blob ([resp.data]))
            const link        = document.createElement('a')
            link.href         = downloadUrl
            link.setAttribute         ('download', resp.headers ["content-disposition"].split ("=") [1])
            document.body.appendChild (link)
            link.click                ()
            link.remove               ()
        })
    },
    async downloadFiles (selected) {
        const r = await session.post    (`data-center/download/`, {selected})
        if (r.data.status == true) {
            const link        = document.createElement('a')
            link.href         = r.data.file_name
            //link.setAttribute         ('download', resp.headers ["content-disposition"].split ("=") [1])
            document.body.appendChild (link)
            link.click                ()
            link.remove               ()
        }
    },
    DC_IMAGE_CHOICES
}

export default data_center
export {data_center}