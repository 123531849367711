<template>
    <Card>
<!--    <template #header>
        <img alt="user header" src="/demo/images/usercard.png">
    </template>
-->    
    <template #title>
        Security Response Calculation
    </template>
    <template #content>
        <div>
            <label class="fixed-label">Please enter the number shown on the Display</label>
            <InputText type="text" v-model="state.challange" />
        </div>
        <div>
            <label class="fixed-label">Is the the security password you need to enter on the Display</label>
            <InputText type="text" v-model="response" disabled />
        </div>
    </template>
    </Card>
</template>

<script>
import Card                             from 'primevue/card'
import { useRoute }                     from 'vue-router'
import { reactive, computed }           from 'vue'
import InputText                        from 'primevue/inputtext'
export default {
    components: {Card, InputText},
    setup () {
        const route     = useRoute ()
        const state     = reactive ({challange : 0})
        const response  = computed (() => {
            const c = Number ("0x" + state.challange)
            return (0x69534557 ^ c) % 99999999
        })
        if (route.query.C) {
            state.challange = route.query.C
        }
        return { state, response }
    }
}
</script>

<style>
.fixed-label {
    display:    inline-block;
    width:      25em;
}
</style>