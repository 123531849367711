<template>
    <TreeTable
        class="smaller"
        :value="state.nodes"
        selectionMode="single"
        v-model:selectionKeys="state.selection"
        :autoLayout="false"
        :rowHover="true"
        :loading="state.loading"
    >
        <Column field="name" :expander="true">
            <template #header>
                Name <Button type="button" icon="fa-solid fa-plus" class="p-button-rounded p-button-primary" @click="newObject()"
                    v-tooltip.top="'Create new Customer'"
                    v-if="lkManager"
                />
            </template>
            <template #body="props">
                <i class="fa-solid" :class="getNodeType(props.node.key)"></i><span style="margin-left: 0.2rem">{{ props.node.data.name }}</span>
            </template>
        </Column>
        <Column field="unique_number" header="Unique Number" headerStyle="width: 21rem" bodyStyle="font-family: monospace;">
        </Column>
        <Column headerStyle="width: 15rem" headerClass="p-text-center" bodyClass="p-text-right">
            <template #body="props">
                <FileUpload style="display: inline-block" mode="basic" name="ecu_info" :url="uploadUrl(props.node)" :auto="true"
                    chooseLabel="Import ECU's"
                    v-if="lkManager && props.node.key[0] == 'G'"
                    v-tooltip.top="'Import ECU\'s'"
                />
                <Button type="button" icon="fa-solid fa-file-certificate"    class="p-button-rounded p-button-text p-button-success" @click="openLicenceKeys(props.node)"
                    v-tooltip.top="'Licence key management'"
                    v-if="props.node.key[0] != 'E'"
                />
                <Button type="button" icon="fa-solid fa-microchip" class="p-button-rounded p-button-text p-button-info"    @click="newObject(props.node, 'ECU')"
                    v-if="lkManager && props.node.key[0] == 'G'"
                    v-tooltip.top="'Create new ECU'"
                />
                <Button type="button" icon="fa-solid fa-layers-plus"   class="p-button-rounded p-button-text p-button-info"    @click="newObject(props.node)"
                    v-if="lkManager && props.node.key[0] != 'E'"
                    v-tooltip.top="'New group'"
                />
                <Button type="button" icon="fa-solid fa-pencil" class="p-button-rounded p-button-text p-button-warning" @click="editObject(props.node)"
                    v-tooltip.top="'Edit'"
                    v-if="lkManager"
                />
            </template>
        </Column>
    </TreeTable>
    <Dialog v-model:visible="state.editDialogVisible"
            @hide="callRevertFunction"
            modal
    >
        <template #header>
            {{ state.model.verbose_name }}
        </template>
        <ObjectEdit :modelName="state.model.name"
                    formName="licence_management"
                    :id="state.objectToEdit"
                    :asNew="state.editAsNew"
                    :overrides="state.overrides"
                    toolbar="bottom" buttons=""
                    :useRouter="false"
                    @editFinished="editFinished"
                    @revertFunction="setRevertFunction"
                    />
    </Dialog>
</template>

<script>
import { onBeforeMount, reactive, computed }             from 'vue'
import auth                     from "@/store/auth"
import TreeTable                from 'primevue/treetable'
import Column                   from 'primevue/column'
import Dialog                   from 'primevue/dialog'
import models                   from '@/store/models'
import ObjectEdit               from '@/components/models/ObjectEdit.vue'
import FileUpload               from 'primevue/fileupload'
import { useRouter }            from 'vue-router'

export default {
    setup () {
        const router = useRouter ()
        const aState = auth.getAuthState ()
        const lkManager = computed (() => aState.user.groups.LICENCE_KEY_MANAGEMENT == true)
        const state = reactive ({ nodes: [],
            selection: [],
            editDialogVisible: false,
            model: null,
            objectToEdit: 0,
            editAsNew: false,
            overrides: {},
            parent: null,
            loading: true
            })
        const obj2Node = (pk, obj, prefix) => {
            const node = {key: prefix + obj [pk], data: obj}
            node.children = []
            if (obj.children) {
                for (const c of obj.children) {
                    node.children.push (obj2Node  (pk, c, "G-"))
                    models.addObject ("Licence_Key_Group", c, "id")
                }
            }
            if (obj.ecus) {
                for (const c of obj.ecus) {
                    node.children.push (obj2Node  (pk, c, "E-"))
                    models.addObject ("Licence_Key_Group", c, "id")
                }
            }
            return node
        }
        onBeforeMount (async () => {
            const {objects} = await models.loadObjects ("Customer")
            state.nodes.splice (0, state.nodes.length)
            for (const c of objects) {
                state.nodes = [...state.nodes, obj2Node ("id", c, "C-")]
            }
            state.loading = false
        })
        const _startEdit = async (objectType, id, asNew, overrides, parent) => {
            const {model}           = await models.getModelInner (objectType)
            state.model             = model
            state.objectToEdit      = id
            state.editAsNew         = asNew
            state.overrides         = overrides
            state.editDialogVisible = true
            state.parent            = parent
        }
        const editObject = async (node) => {
            let objectType = "Licence_Key_Group"
            if (node.key [0] == "C") objectType = "Customer"
            if (node.key [0] == "E") objectType = "ECU"
            await _startEdit (objectType, node.data.id, false, {}, null)
        }
        const newObject = (parent, cls) => {
            let override = {}
            if (!cls) cls = "Licence_Key_Group"
            if (parent) {
                const parentData   = [parent.data.id, parent.data.name]
                if (parent.key [0] == "C")
                    override.customer = parentData
                else if (cls == "ECU")
                    override.group    = parentData
                else
                    override.parent   = parentData
            } else {
                cls   = "Customer"
            }
            _startEdit (cls, 0, true, override, parent)

        }
        const getNodeType = (key) => {
            if (key [0] == "C") return "fa-user-circle"
            if (key [0] == "G") return "fa-id-card"
            return "fa-microchip"
        }
        let _revertFunction = null
        const setRevertFunction = (fct) => {
            _revertFunction = fct
        }
        const callRevertFunction = () => {
            _revertFunction ()
        }
        const editFinished = ({action, object}) => {
            if (action == "save") {
                if (state.parent) {
                    let prefix = "G-"
                    if (object.serial_number) prefix = "E-"
                    state.parent.children = [...state.parent.children,
                        obj2Node ("id", object, prefix)]
                }
            }
            state.editDialogVisible = false
        }
        const uploadUrl = (node) => {
            return `/api/v2/import-ecus/${node.data.id}/`
        }
        const openLicenceKeys = (node) => {
            let route    = "licence-keys-group"
            if (node.key [0] == "C") route = "licence-keys-customer"
            if (node.key [0] == "E") route = "licence-keys-ecu"
            console.log (route,router)
            router.push ({name : route, params: {id: node.data.id}})
        }
        return { state, lkManager, uploadUrl, openLicenceKeys
               , editObject, newObject, getNodeType, editFinished
               , setRevertFunction, callRevertFunction
               }
    },
    components: {TreeTable, Column, Dialog, FileUpload, ObjectEdit}
}
</script>

<style>
    .smaller.p-treetable .p-treetable-tbody > tr > td {
        padding: 5px 0
    }
</style>