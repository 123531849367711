<template>
    <span class="spread">
        <Button icon="fa-duotone fa-file-code"         
            label="Generate Board Code" 
            class="p-button-success p-button-lg"
            @click="generateBoardCode"
            >
        </Button>
    </span>
    <table>
        <tbody>
            <tr style="height: auto">
                <td><div class="hardware">{{ state.hardware.name }}</div></td>
            </tr>

            <tr v-for="ph in state.hardware.pheripherals" :key="ph.id">
                <td><div class="container"><div class="v-full">&nbsp;</div><div class="h-line-50">&nbsp;</div></div></td>
                <td>
                    <div class="pheripheral" @click="editObject (ph)">
                        <h3>{{ ph.data.name}}</h3>
                        <h4>{{ ph.type}}</h4>
                    </div>
                </td>
                <td v-for="c, idx in configAttrs (ph)" :key="idx">
                    <div class="container">
                        <div class="h-line">&nbsp;</div>
                        <div class="config">
                            <h5>{{ c.value }}</h5>
                            <h6>{{ c.attr  }}</h6>
                        </div>
                    </div>
                </td>
            </tr>            
            <tr style="height: auto">
                <td><div class="add-new">
                    <SplitButton 
                        icon="fa-duotone fa-plus" 
                        class="p-button-warning" 
                        :model="state.pheripheral_classes"
                    />
                </div></td>
            </tr>
        </tbody>
    </table>
    <Dialog v-model:visible="state.editDialogVisible"
            @hide="callRevertFunction"
            modal
    >
        <template #header>
            {{ state.model.verbose_name }}
        </template>
        <ObjectEdit :modelName="state.model.name"
                    formName="default"
                    :id="state.objectToEdit.data.id"
                    :asNew="state.editAsNew"
                    :overrides="state.overrides"
                    toolbar="bottom" buttons="d"
                    :useRouter="false"
                    @editFinished="editFinished"
                    @revertFunction="setRevertFunction"
                    />
    </Dialog>

</template>

<script>
import { onBeforeMount , reactive }     from 'vue'
import Dialog                           from 'primevue/dialog'
import models                           from '@/store/models'
import session                          from '@/api/session'
import ObjectEdit                       from '@/components/models/ObjectEdit.vue'
import SplitButton                      from 'primevue/splitbutton'
export default {
    components: {Dialog, ObjectEdit, SplitButton},
    props: {
        id: {type: Number, required: true}
    },
    setup (props) {
        const state = reactive ({ 
            hardware: {pheripherals : []},
            editDialogVisible: false,
            editAsNew: false,
            objectToEdit: {data:{id:-1}},
            overrides: {},
            pheripheral_classes: [],
            newPheripheral: null
        })
        onBeforeMount (async () => {
            const resp = await session.get (`hardware-config/${props.id}/`)
            if (resp.status == 200) {
                state.hardware            = resp.data.hardware
                state.pheripheral_classes = []
                const overrides           = {
                    hardware: [state.hardware.id, state.hardware.name]}
                for (let s of resp.data.pheripheral_classes) {
                    state.pheripheral_classes.push ({
                        label: s.name,
                        command: () => {
                            state.newPheripheral = s
                            _startEdit (s.model, 
                                    { data: {
                                    id: 0, 
                                    hardware: [state.hardware.id, state.hardware.name]},
                                model:       s.model,
                                type:        s.name,
                                none_config: s.none_config
                                }, 
                                true, 
                                overrides)
                        }
                    })
                }
            }
        })
        const configAttrs = (ph) => {
            const result = []
            for (const [key, value] of Object.entries (ph.data)) {
                if (! ph.none_config.includes (key)) {
                    let cfg = {attr: key, value}
                    if (Array.isArray (value)) {
                        cfg ["value"]   = value [1]
                        cfg ["valueId"] = value [0]
                    }
                    result.push (cfg)
                }
            }
            return result;
        }
        const _startEdit = async (objectType, ph, asNew, overrides) => {
            const {model}           = await models.getModelInner (objectType)
            state.model             = model
            state.objectToEdit      = ph
            state.editAsNew         = asNew.toString ()
            state.overrides         = overrides
            state.editDialogVisible = true
        }
        const editObject = async (node) => {
            await _startEdit (node.model, node, false, {})
        }
        let _revertFunction = null
        const setRevertFunction = (fct) => {
            _revertFunction = fct
        }
        const callRevertFunction = () => {
            _revertFunction ()
        }
        const editFinished = ({action, object}) => {
            console.log (object, action)
            if (action == "save") {
                state.objectToEdit.data = object
                if (state.editAsNew == "true") {
                    state.hardware.pheripherals.push (state.objectToEdit)
                }
            } else if (action == "delete") {
                const idx = state.hardware.pheripherals.indexOf (state.objectToEdit)
                state.hardware.pheripherals.splice (idx, 1)
            }
            state.editDialogVisible = false
        }
        const generateBoardCode = async () => {
            await session.triggerFileDownload (`hardware-config/${props.id}/generate-code/`)
        }
        return {state, configAttrs, setRevertFunction, callRevertFunction, 
            editFinished, editObject, generateBoardCode}
    }
}
</script>

<style>
:root {
    --line-width: 2px;
    --h-line-length: 20px;
    --line-color: black;
}

table {
    border-collapse: collapse;   
    font-size: 80%; 
}

tr {
    height:     6em;
}
th, td {
    padding: 0; 
    height: 100%;   
}

.container {
    position:           relative;
    height:             100%;
    width:              100%;
}

.pheripheral, .hardware, .config {
    border-radius:      5px;
    padding:            1em;
    box-shadow:         0 5px 15px rgba(0, 0, 0, 0.15);
    text-align:         center;
}

.hardware {
    background-color: #f5cc7f;
    font-size:          150%;
    font-weight:        bold;
}

.add-new {
    text-align:         center;
}

.pheripheral {
    margin:             1em 0 1em 1em;
    background-color: #8dccad;
    z-index:            2;
    position:           relative;
    cursor:             pointer;
}

.pheripheral h3 {
    font-size:          140%;
    margin:             0;
    font-weight:        bold;
}

.pheripheral h4 {
    font-size:          110%;
    margin:             0;
    font-style:         italic
}
.config {
    background-color: #7b9fe0;
    margin-left:        var(--h-line-length);
}
.config h5 {
    font-size:          120%;
    margin:             0;
    font-weight:        bold;
    width:              10rem;
}

.config h6 {
    font-size:          100%;
    margin:             0;
    font-style:         italic;
}

.h-line-50 {
    border-top:         var(--line-width) solid var(--line-color);
    position:           relative;
    bottom:             50%;
    left:               50%;
    width:              70%;
    z-index:            1;
}

.h-line {
    border-top:         var(--line-width) solid var(--line-color);
    width:              var(--h-line-length);
    position:           relative;
    top:                49%;
}
.v-full {
    border-left:        var(--line-width) solid var(--line-color);
    position:           relative;
    height:             100%;
    width:              100%;
    left:               50%;
}

.spread button {
    margin-right: 1em;
    margin-bottom: 2em;
}
</style>