<template>
    <h6 v-if="errors.length > 0 && title.length > 0">{{ title }}</h6>
    <ul>
        <li v-for="e,idx in errors" :key="idx">{{ e}}</li>
    </ul>
</template>

<script>
export default {
  props: {
    title: { type: String, default: ""},
    errors: { type: Array, default : () => {return [] }}
  },

}
</script>

<style scoped>
    h6 {margin-bottom: 0.5em; color: red}
    ul {
        list-style: none;
        padding:    0px;
        margin:     0px;
        color:    red;
        margin-bottom: 0.5em;
    }
</style>