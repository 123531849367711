<template>
    <Card>
        <template #title v-if="!state.loading">{{ state.model.verbose_name }} {{state.object.name }}</template>
        <template #title v-else>Loading</template>
        <template #content>
            <ObjectEdit :modelName="modelName"
                        :formName="formName"
                        :id="id"
                        :asNew="asNew"
                        :toolbar="toolbar"
                        v-model:model="state.model"
                        v-model:loading="state.loading"
                        v-model:object="state.object"
                        />
        </template>
    </Card>
</template>

<script>
import { reactive }             from 'vue'
import Card                     from 'primevue/card'
import ObjectEdit               from '@/components/models/ObjectEdit'
export default {
    props: {
        modelName: {type: String, required: true},
        formName:  {type: String},
        id:        {type: String, required: true},
        asNew:     {type: String, default: "false"},
        toolbar:   {type: String, default: "both"},
    },
    setup () {
        const state = reactive ({loading: true, model: null, object: null})
        return {state}
    },
    components: {Card, ObjectEdit}
}
</script>

<style>

</style>