<template>
    <div>
        <Button label="Update Filter Status" icon="fa-duotone fa-filter-list" @click="statusUpdate" />
        <Paginator v-model:first="pag.first"
                   v-model:rows="pag.perPage" 
                   :totalRecords="modelsVisible.length" 
                   :rowsPerPageOptions="[5, 10, 20, 30]" />
        <Panel v-for="m in modelsOnPage" :key="m.key" class="translation-table"
        :header="moduleHeader (m)" :toggleable="true" :collapsed="true">
            <template #icons>
                <i :class="classes('icon', 'module-overall', m)" />
            </template>            
            <i :class="classes('icon', 'module', m)" />
            <div :class="classes ('srcText')">
                {{ translations [m.key].source.text }}
            </div>
            <div :class="classes ('tarText')">
                <InputText v-model="translations [m.key].target.text" />
            </div>
            <div>
                <i class="icon-nodesc" />
                <div :class="classes ('srcDesc')">
                    <span v-if="translations [m.key].source.description">
                        {{ translations [m.key].source.description}}
                    </span>
                    <span v-else class="nosource">No source description</span>
                </div>
                <div :class="classes ('tarDesc')">
                    <TextArea v-model="translations [m.key].target.description" rows="1" />
                </div>
            </div>
            <Panel :header="parameterHeader(m)" :toggleable="true" :collapsed="true">
                <template #icons>
                    <i :class="classes('icon', 'parameters', m)" />
                </template>            
                <div v-for="p in filteredParameters (m)" :key="p.key" :class="classes('row')">
                    <i :class="classes('icon', 'parameter', p)" />
                    <div :class="classes ('srcText')">
                        {{ translations [p.key].source.text }}
                    </div>
                    <div :class="classes ('tarText')">
                        <InputText v-model="translations [p.key].target.text" />
                    </div>
                    <div>
                        <i class="icon-nodesc" />
                        <div :class="classes ('srcDesc')">
                            <span v-if="translations [p.key].source.description">
                                {{ translations [p.key].source.description}}
                            </span>
                            <span v-else class="nosource">No source description</span>
                        </div>
                        <div :class="classes ('tarDesc')">
                            <TextArea v-model="translations [p.key].target.description" rows="1" />
                        </div>
                    </div>
                    <Panel :header="choiceHeader(p)" :toggleable="true" :collapsed="true"
                        v-if="p.choices.length"
                        >
                        <template #icons>
                            <i :class="classes('icon', 'choices', p)" />
                        </template>            
                        <div v-for="c in filteredChoices (p)" :key="c.key" :class="classes('row')">
                            <i :class="classes('icon', 'choice', c)" />
                            <div :class="classes ('srcText')">
                                {{ translations [c.key].source.text }}
                            </div>
                            <div :class="classes ('tarText')">
                                <InputText v-model="translations [c.key].target.text" />
                            </div>
                            <div>
                                <i class="icon-nodesc" />
                                <div :class="classes ('srcDesc')">
                                    <span v-if="translations [c.key].source.description">
                                        {{ translations [c.key].source.description}}
                                    </span>
                                    <span v-else class="nosource">No source description</span>
                                </div>
                                <div :class="classes ('tarDesc')">
                                    <TextArea v-model="translations [c.key].target.description" rows="1" />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
            </Panel>
        </Panel>
    </div>
</template>

<script>
import { ref, computed, reactive }      from 'vue'
import Paginator                        from 'primevue/paginator';
import Panel                            from 'primevue/panel'
import InputText                        from 'primevue/inputtext'
import TextArea                         from 'primevue/textarea'
export default {
    components: {Paginator, Panel, InputText, TextArea},
    props: {
        modelValue:     {type: Object, required: true},
        modules:        {type: Array,  required: true},
        filterStatus:   {type: String,                   default: 'all'},
        filterText:     {type: String,                   default: ''}
    },
    emits: ["update:modelValue"],
    setup (props, {emit}) {
        const translations = ref (props.modelValue)
        const pag  = reactive ({first: 0, perPage: 5})
        const _is_missing = (key) => {
            const tr = translations.value [key]
            if (tr === undefined) {
                console.log ("ISE", key)
                return 1
            }
            if (!tr.target.text || (tr.source.description && !tr.target.description)) 
                return 1
            return 0
        }
        const countChoiceTranslations  = p => {
            let total  = 0, missing = 0, finished = 0
            for (const c of p.choices) {
                total    += 1
                missing  += _is_missing (c.key)
                finished += _is_finished (c.key)
            }
            return {total, missing, finished}
        }
        const countParameterTranslations = (m) => {
            let total  = 0, missing = 0, finished = 0
            for (const p of m.parameters) {
                total    += 1
                missing  += _is_missing (p.key)
                finished += _is_finished (p.key)
                const C = countChoiceTranslations (p)
                total    += C.total
                missing  += C.missing
                finished += C.finished
            }
            return {total, missing, finished}
        }
        const countModuleTranslations = (m) => {
            let total  = 1, missing = 0, finished = 0
            missing   += _is_missing (m.key)
            finished  += _is_finished (m.key)
            const    C = countParameterTranslations (m)
            total    += C.total
            missing  += C.missing
            finished += C.finished
            return {total, missing, finished}
        }
        const _is_finished = (key) => {
            const tr = translations.value [key]
            if (tr === undefined) {
                console.log ("ISE", key)
                return 0
            }
            if (tr.finished) 
                return 1
            return 0
        }
        const choiceTranslationsFinished  = p => {
            for (const c of p.choices) {
                if (!_is_finished (c.key)) 
                    return false
            }
            return true
        }
        const parameterTranslationsFinished = (m) => {
            for (const p of m.parameters) {
                if (!_is_finished (p.key))
                    return false
                if (!choiceTranslationsFinished (p))
                    return false
            }
            return true
        }
        const moduleTranslationsFinished = (m) => {
            if (!_is_finished (m.key))
                return false
            if (!parameterTranslationsFinished)
                return false
            return true
        }
        const moduleHeader = m => {
            const {total, missing} = countModuleTranslations (m)
            const tr = translations.value [m.key]
            return `Module ${tr.source.text}, ${m.parameters.length} Parameter(s) (${missing} of ${total} translation(s) missing)`
        }
        const parameterHeader = (m) => {
            const {total, missing} = countParameterTranslations (m)
            return `Parameter(s) (${missing} of ${total} translation(s) missing)`
        }
        const choiceHeader = (p) => {
            const {total, missing} = countChoiceTranslations (p)
            return `Choices(s) (${missing} of ${total} translation(s) missing)`
        }
        const classes = (k, kind, obj) => {
            let data
            let missing = 0
            if (kind) {
                data = translations.value [obj.key]
                if (kind == "module-overall") 
                    ({missing} = countModuleTranslations    (obj))
                else if (kind == "parameters")
                    ({missing} = countParameterTranslations (obj))
                else if (kind == "choices")
                    ({missing} = countChoiceTranslations    (obj))
                else {
                    if (!data.target.text || (data.source.description && !data.target.description))
                        missing = 1
                }
            }
            switch (k) {
                case 'srcText' : return "stext"
                case 'tarText' : return "dtext"
                case 'srcDesc' : return "sdescription"
                case 'tarDesc' : return "ddescription"
                case 'row'     : return (data & 0x01) ? "odd" : "even"
                case 'row-s'     : 
                    return (data.target.text && data.target.description) ? "done" : ""
                case 'icon'     : 
                    return (missing == 0)
                        ? "icon-nodesc idone fa-duotone fa-circle-check" : "icon-nodesc iwork fa-duotone fa-person-digging"
            }
        }
        const statusUpdate = () => {
            console.log ("Start status update", Date.now ())
            for (const e of Object.values (translations.value)) {
                e.finished = (e.target.text || "").length > 0
                if (props.hasDescription && e.source.description) {
                    e.finished = e.finished && ((e.target.description || "").length > 0)
                }
            }
            emit ("update:modelValue", translations.value)
            console.log ("end   status update", Date.now ())
        }
        const modelsVisible = computed (() => {
            console.log ("Start update modelsVisible", Date.now ())
            let result = props.modules
            if (props.filterStatus == "translated")
                result = result.filter (m => {
                    return moduleTranslationsFinished (m)
                })
            else if (props.filterStatus == "untranslated")
                result = result.filter (m => {
                    return !moduleTranslationsFinished (m)
                })
            console.log ("End   update modelsVisible", Date.now ())
            return result
        })
        const modelsOnPage = computed (() => {
            return modelsVisible.value.slice (pag.first, pag.first + pag.perPage)
        })
        const filteredParameters = m => {
            let result = m.parameters
            const _m = p => {
                let tr = translations.value [p.key]
                if (! tr.finished) 
                    return true
                for (const c of p.choices)
                     tr = translations.value [c.key]
                    if (! tr.finished) 
                        return true
                return false
            }
            if (props.filterStatus == "translated")
                result = result.filter (p => { return ! _m (p) })
            else if (props.filterStatus == "untranslated")
                result = result.filter (p => { return   _m (p) })
            return result
        }
        const filteredChoices = p => {
            let result = p.choices
            if (props.filterStatus == "translated")
                result = result.filter (c => { 
                    return   translations.value [c.key].finished

                })
            else if (props.filterStatus == "untranslated")
                result = result.filter (c => { 
                    return ! translations.value [c.key].finished
                })
            return result
        }
        return {pag, classes, translations, modelsVisible, modelsOnPage, statusUpdate,
            moduleHeader, parameterHeader, choiceHeader,
            filteredParameters, filteredChoices}
    }

}
</script>

<style lang="scss" scoped>
    .p-panel .p-panel-content {
        padding-right: 0px;
    }
    .translation-table {
        .done, .done .p-inputtext {
            background-color: rgba(191, 240, 186, 0.664);
        }
        .idone {
            color: green;
        }
        .iwork {
            color: red;
        }
        .icon-nodesc {
            display:        inline-block;
            width:          1rem;
            margin-right:   1rem;
        }
        .stext-nodesc {
            width:      35%;
            display:    inline-block;
        }
        .dtext-nodesc {
            width:      60%;
            display:    inline-block;

            .p-inputtext {
                width: 100%;
            }

        }
        .stext {
            width:      35%;
            display:    inline-block;
        }
        .dtext {
            width:      60%;
            display:    inline-block;

            .p-inputtext {
                width: 100%;
            }
        }
        .sdescription {
            width:      35%;
            display:    inline-block;
        }
        .ddescription {
            width:      60%;
            display:    inline-block;

            .p-inputtextarea {
                width:      100%;
                padding:    0;
                height:     2.2rem;
            }
        }
        .odd {
            background-color:   #F8F8F8;
            .p-inputtext {
                background-color: #F8F8F8;
            }
        }
        .even, .odd {
            padding:            5px 0;
            border-bottom:      1px solid black;
        }
        .nosource {
            font-style: italic;
            color:    lightgray;
        }
    }
</style>