import { createApp }        from 'vue'
import App                  from './App.vue'
import router               from './router'
import PrimeVue             from 'primevue/config'
import VueKonva             from 'vue-konva'
import Button               from "primevue/button"
import Ripple               from 'primevue/ripple'
import ToastService         from "primevue/toastservice"
import Tooltip              from "primevue/tooltip"
import ConfirmationService  from 'primevue/confirmationservice';
import ParameterTable       from './components/isew/instance-edit/ParameterTable'
import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"
import "@fortawesome/fontawesome-pro/css/all.css"
//import "@fortawesome/fontawesome-pro/js/fontawesome.js"
import './assets/layout/layout.scss';
import './assets/layout/flags/flags.css';

const app = createApp(App)
app.use         (router)
app.use         (PrimeVue)
app.use         (ToastService)
app.use         (ConfirmationService)
app.use         (VueKonva)

app.directive   ("tooltip",     Tooltip)
app.directive   ("ripple",      Ripple)

app.component   ("Button",      Button)
app.component   ("ParameterTable",      ParameterTable)

app.mount       ("#app")
