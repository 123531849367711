<template>
<div>
  <div class="field col-12" v-if="! localObject.video">
      Please Upload a video file first
  </div>
  <div class="field col-12 md:col-6">
      <label for="name">Name</label>
      <InputText style="width: 40rem"
          v-model="localObject.name"
      />
  </div>
  <div class="field col-12 md:col-6">
      <label for="video">Video File</label>
      <Dropdown style="width: 40rem"
          v-model="localObject.video" :options="videos" optionValue="file_name" optionLabel="file_name"
          @change="updateDuration"
      >
        <template #value="{ value } ">
          <div class="p-dropdown-car-option">
            <span style="display: inline-block; width: 32rem">{{ value }}</span>
            <span>{{ durationForValue (value) }} s</span>
          </div>
        </template>
        <template #option="{ option } ">
          <div class="p-dropdown-car-option">
            <span style="display: inline-block; width: 32rem">{{option.file_name}}</span>
            <span>{{ option.duration }} s</span>
          </div>
        </template>
      </Dropdown>
      <div class="field col-12">
          <FileUpload 
              mode="basic" 
              name="video" 
              accept="video/*" 
              chooseLabel="Upload a new video file"
              url="/api/v2/data-center/video/upload/"
              :maxFileSize="52428800"
              auto
              @progress="uploadProgress"
              @upload="uploadComplete"
              @before-upload="startUpload"
              style="margin-left: 20rem"
              />
          <ProgressBar :value="fileupload.progress" 
            v-if="fileupload.inProgress"
          />
          <InputNumber v-model="localObject.duration" disabled />
      </div>
  </div>
  <div class="field col-12">
      <label for="icon">Icon</label>
      <Dropdown style="width: 40rem"
          optionLabel="name" optionValue="value" :options="DC_IMAGE_CHOICES"
          v-model="localObject.icon"
      >
        <template #value="{ value } ">
          <div class="p-dropdown-car-option">
            <i :class="iconForIcon(value)" />&nbsp;
            <span>{{ nameForIcon (value) }}</span>
          </div>
        </template>
        <template #option="{ option } ">
          <div class="p-dropdown-car-option">
            <i :class="iconForIcon(option.value)" />&nbsp;
            <span>{{option.name}}</span>
          </div>
        </template>
      </Dropdown>
  </div>
  <div class="field col-12">
      <label for="parent">Folder</label>
      <TreeSelect v-model="localObject.folder_id" style="width: 40rem"
          :options="folders"
          optionLabel="title" optionValue="id" optionGroupLabel="title"
          :optionGroupChildren="['subfolders']"
          />
  </div>
  <div style="text-align: center; margin-top:1em">
      <Button label="Save" @click="save" :disabled="!localObject.video"/>&nbsp;
      <Button class="p-button-warning" label="Cancel" @click="close"/>
  </div>
</div>
</template>

<script>
import { reactive, onBeforeMount, ref } from 'vue'
import InputText                        from 'primevue/inputtext'
import InputNumber                      from 'primevue/inputnumber'
import FileUpload                       from 'primevue/fileupload'
import ProgressBar                      from 'primevue/progressbar'
import TreeSelect                       from 'primevue/treeselect'
import Dropdown                         from 'primevue/dropdown'
import DC                               from "@/api/data_center"

const DC_IMAGE_CHOICES = 
    [ {value: "images/folder-tree-24.png",         name: "Tree",        icon: "fa-folder-open"}
    , {value: "images/rectangle-list-24.png",      name: "List",        icon: "fa-rectangle-list"}
    , {value: "images/screwdriver-wrench-24.png",  name: "Spare Part",  icon: "fa-screwdriver-wrench"}
    , {value: "images/video-24.png",               name: "Video",       icon: "fa-video"}
    ]

export default {
    props: {
        object: { type: Object, required: true},
        parent: { type: Object, required: true},
    },
    emits: ["update:object", "objectSaved", "close"],
    components: {InputText, Dropdown, FileUpload, ProgressBar, TreeSelect, InputNumber},
    setup (props, { emit }) {
        const localObject = reactive ({...props.object})
        const fileupload  = reactive ({inProgress: false, progress: 0})
        const folders     = ref ([])
        const videos      = ref ([])
        const fid         = localObject.folder_id
        if (!localObject.icon)
            localObject.icon = DC_IMAGE_CHOICES [3].value
        localObject.folder_id = {[fid] : true}
        onBeforeMount (async () => {
            const r       = await DC.getAssets ("videos")
            folders.value = r.folders
            videos.value  = r.assets
        })
        const close = () => {
            emit ("close")
        }
        const save = async () => {
            localObject.folder_id = Object.keys (localObject.folder_id) [0]
            const r = await DC.saveVideo (localObject)
            emit  ("update:object", localObject)
            emit  ("objectSaved",   {node: r.data.node, old_parent: fid})
            close ()
        }
        const startUpload = () => {
            fileupload.inProgress    = true
        }
        const uploadProgress = ({progress}) => {
            fileupload.progress = progress
        }
        const uploadComplete = ({files}) => {
            if (! localObject.name) {
                localObject.name     = files [0].name
            }
            localObject.video        = files [0].name
            fileupload.inProgress    = false
            this.$el.querySelector ("#name").focus ()
        }
        const iconForIcon = (img) => {
          for (let e of DC_IMAGE_CHOICES) {
            if (e.value == img) return `fa-duotone ${e.icon}` 
          }
          return "fa-duotone fa-question"
        }
        const nameForIcon = (img) => {
          for (let e of DC_IMAGE_CHOICES) {
            if (e.value == img) return e.name
          }
          return img
        }
        const durationForValue = (file_name) => {
          for (const e of videos.value) {
            if (e.file_name == file_name)
              return e.duration
          }
          return 0
        }
        const updateDuration = ({value}) => {
          localObject.duration = parseFloat (durationForValue (value) * 100)
        }
        return {localObject, DC_IMAGE_CHOICES, save, close, 
            fileupload, startUpload, uploadProgress, uploadComplete, 
            iconForIcon, nameForIcon, durationForValue, updateDuration,
            folders, videos}
    }
}
</script>

<style scoped>
    label {
        display:    inline-block;
        width:      20rem;
    }
</style>