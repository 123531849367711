<template>
    <div>
        <Toolbar>
            <template #start>
                <Button icon="fa-regular fa-times"          label="Cancel" class="p-button-warning" @click="$emit('cancel')" />
                <Button icon="fa-regular fa-double-check"   label="Apply"  class="p-button-succes"  @click="save(false)" />
            </template>
            <template #end>
                see as <Dropdown :options="secLevelList" v-model="state.secLevel" optionLabel="name" optionValue="value" />
                <Button icon="fa-regular fa-save"           label="Save"   class="p-button-success" @click="save(true)" />
            </template>
        </Toolbar>
        <div>
            <ParameterTable
                :secLevel="state.secLevel"
                :spec="spec"
                :values="state.values"
                :loading="state.loading"
                :secLevels="secLevels"
                :projectId="projectId"
                @update:value="setNewValue"
                @move-row="moveRow"
                @remove-row="removeRow"
                @add-choice="addChoice"
                @add-instance="addInstance"
            />
        </div>
    </div>
</template>

<script>
import { reactive }                 from 'vue'
import projects                     from "@/api/projects"
import Dropdown                     from 'primevue/dropdown'
import Toolbar                      from 'primevue/toolbar'
import ParameterTable               from './instance-edit/ParameterTable'

const secLevels = { 0b11101010 : "Operator"
                  , 0b11101000 : "Supervisor"
                  , 0b11100000 : "Technician"
                  , 0b11000000 : "Dealer"
                  , 0b10000000 : "Supplier"
                  }

export default {
    props: {
        projectId:  {type: Number, required: true},
        object:     {type: Object, required: true},
        spec:       {type: Object, required: true},
    },
    emits: ["cancel", "update-instance"],
    setup (props, {emit}) {
        const state = reactive ({ values : [], secLevel: 0b10000000, loading: true})
        let   p
        if (props.object.mpk)
            p = projects.newInstance (props.projectId, props.object.mpk)
        else
            p = projects.loadInstanceValues (props.object.pk)
        p.then (({data}) => {
            if (data.status) {
                state.values = data.instance_values
            }
            state.loading = false
        })
        let secLevelList = []
        for (const [key, value] of Object.entries (secLevels)) {
            secLevelList.push ({name: value, value: parseInt (key)})
        }
        const setNewValue = ({value, pk}) => {
            state.values [pk] = value
        }
        const moveRow = ({spec, by, idx}) => {
            let list = state.values [spec.pk].value
            const obj = list.splice (idx, 1) [0]
            list.splice (idx + by, 0, obj)
        }
        const removeRow = ({spec, idx}) => {
            let list = state.values [spec.pk].value
            list.splice (idx, 1) [0]
        }
        const addChoice = (spec) => {
            let list = state.values [spec.pk].value
            list.push ({uid: 0})
        }
        const addInstance = ({pk, instance, ppk, index}) => {
            //console.log ("addInstance IE", pk, instance, ppk, index)
            let obj
            if (ppk) {
                obj = state.values [ppk].value [index]
            } else {
                obj = state.values
            }
            let list = obj [pk].value
            list.push (instance)
        }
        const save = async (close) => {
            const res = await projects.saveInstance (props.projectId, state.values)
            emit     ("update-instance", res)
            if (close)
                emit ("cancel", res)
        }
        return {state, secLevels, secLevelList, setNewValue, moveRow, removeRow, save, addChoice, addInstance}
    },
    components: {ParameterTable, Dropdown, Toolbar}
}
</script>

<style scoped>
    .info {
        width: 20px;
    }
</style>