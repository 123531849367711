<template>
    <div class="p-grid p-jc-center full-height">
        <Card class="p-col-3">
            <template #title>
                Login
            </template>
            <template #content>
                <form class="p-fluid">
                    <FormField field-name="username" label="Username" :errors="authState.errors.username">
                        <InputText id="username" type="text" v-model="form.username" />
                    </FormField>
                    <FormField field-name="username" label="Username" :errors="authState.errors.password">
                        <Password v-model="form.password" toggleMask :feedback="false"></Password>
                    </FormField>
                    <ErrorList :errors="authState.errors.non_field_errors" />
                    <div class="p-field p-grid">
                        <div class="p-col-12 p-md-8 p-offset-3">
                            <Button label="Login" icon="fa-duotone fa-sign-in" @click="login"/>
                        </div>
                    </div>
                </form>
            </template>
            <template #footer>
                <div>
                    <router-link :to="{name: 'register'}">Not registered? Created an Account</router-link>
                </div>
                <div><router-link :to="{name: 'password_reset'}">reset password</router-link></div>
            </template>
        </Card>
    </div>
</template>

<script>

import { reactive } from 'vue'
import { useRouter  } from 'vue-router'
import Auth         from "@/store/auth"
import Card         from "primevue/card"
import Button       from "primevue/button"
import InputText    from "primevue/inputtext"
import Password     from "primevue/password"
import FormField    from "@/components/FormField"
import ErrorList    from "@/components/ErrorList.vue"

export default {
    setup() {
        const router = useRouter ()
        const form   = reactive ({username: '',password: ''})
        const login  = async () => {
            await Auth.login (form)
            if (Auth.isLoggedIn ())
                router.push ({ path : "/"})
        }
        return {form, login, authState : Auth.getAuthState ()}
    },
    components: {Card, Button, InputText, Password, FormField, ErrorList},
}
</script>

<style lang="scss" scoped>
    .full-height {
        height: 99%;
    }

</style>
