<template>
    <Dropdown filter
        v-model="state.defaultValue"
        @change="changed"
        :options="objects"
        :optionLabel="field.display"
        :optionValue="field.pk"
        :placeholder="placeholder"
        showClear
        />
</template>

<script>
import Dropdown                 from 'primevue/dropdown'
import api                      from '@/api/models'
import { reactive, computed }   from 'vue'

export default {
    name: "ForeignKeyInput",
    components: {Dropdown},
    emits: ["input", "update:modelValue"],
    props: {
        field:      {type: Object, required: true},
        object:     {type: Object},
        modelValue: {required: true},
        id:         {type: String}
    },
    setup (props, {emit}) {
        const state = reactive ({allObjects: [], defaultValue: null})
        api.autoComplete (props.field.model, {}).then (({data}) => {
            if (data.status) {
                state.allObjects = data.objects
                state.defaultValue = (props.modelValue || [null, ""]) [0]
            }
        })
        const objects = computed (() => {
            let objects = state.allObjects
            if (props.field.filter) {
                objects = objects.filter ((item) => {
                    const obj = props.object
                    {item, obj} // elsint hack
                    return eval (props.field.filter)
                })
            }
            return objects
        })
        const changed = (newValue) => {
            const value = newValue.value
            state.defaultValue = value
            const found = state.allObjects.filter ((i) => i [props.field.pk] == value)
            const data  = {value: [null, ""]}
            if (found.length == 1) {
                const o = found [0]
                data.value = [o [props.field.pk], o [props.field.display]]
            }
            emit  ('input',             data)
            emit  ('update:modelValue', data.value)
        }
        const placeholder = `Please select a ${props.field.label}`
        return {state, objects, changed, placeholder}
    }
}
</script>
