<template>
    <div class="translation-table">
        <Button label="Update Filter Status" icon="fa-duotone fa-filter-list" @click="statusUpdate"/>
        <div 
            v-for="(tr, idx) in displayTr" :key="tr.key"
            :class="classes('row', idx)"
        >
            <i :class="classes('icon', tr)" />
            <div :class="classes ('srcText')" style="white-space:pre-wrap">
                {{ tr.source.text }}
            </div>
            <div :class="classes ('tarText')">
                <TextArea v-model="tr.target.text" @blur="checkTranslations (tr, 'text')" rows="2" />
                <small class="error" v-if="tr.error_hint">{{tr.error_hint}}</small>
            </div>
            <div v-if="hasDescription">
                <i class="icon-nodesc" />
                <div :class="classes ('srcDesc')" style="white-space:pre-wrap">
                    {{ tr.source.description || 'No source description'}}
                </div>
                <div :class="classes ('tarDesc')">
                    <TextArea v-model="tr.target.description" rows="1" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed }                from 'vue'
//import InputText                        from 'primevue/inputtext'
import TextArea                         from 'primevue/textarea'

export default {
    components: {TextArea},
    props: {
        modelValue :    {type: Array,   required: true},
        hasDescription: {type: Boolean,                  default: false},
        filterStatus:   {type: String,                   default: 'all'},
        filterText:     {type: String,                   default: ''}
    },
    emits: ["update:modelValue"],
    setup (props, {emit}) {
        const translations = ref (props.modelValue)
        const displayTr = computed (() => {
            let result
            if (props.filterStatus == 'all') {
                result = translations.value
            } else if (props.filterStatus == 'translated') {
                result = translations.value.filter (e => {
                    return e.finished
                })
            } else {
                result = translations.value.filter (e => {
                    return !e.finished
                })
            }
            if (props.filterText) {
                let pat = new RegExp (props.filterText, "i")
                result = result.filter (e => {
                    let found =   e.source.text       .search (pat) >= 0
                                || (e.target.text || "").search (pat) >= 0
                    if (props.hasDescription) {
                        found |=   (e.source.description || "").search (pat) >= 0
                                || (e.target.description || "").search (pat) >= 0
                    }
                    return found
                })
            }
            return result
        })
        const classes = (k, data) => {
            if (props.hasDescription) {
                switch (k) {
                    case 'srcText' : return "stext"
                    case 'tarText' : return "dtext"
                    case 'srcDesc' : return "sdescription"
                    case 'tarDesc' : return "ddescription"
                    case 'row'     : return (data & 0x01) ? "odd" : "even"
                    case 'row-s'     : 
                        return (data.text && data.description) ? "done" : ""
                    case 'icon'     : 
                        return (data.target.text && (!data.source.description || data.target.description))
                            ? "icon-nodesc idone fa-duotone fa-circle-check" : "icon-nodesc iwork fa-duotone fa-person-digging"
                }
            } else {
                switch (k) {
                    case 'srcText' : return "stext-nodesc"
                    case 'tarText' : return "dtext-nodesc"
                    case 'row'     : return (data & 0x01) ? "odd" : "even"
                    case 'row-s'     : 
                        return (data.text) ? "done" : ""
                    case 'icon'     : 
                        return (data.target.text) 
                            ? "icon-nodesc idone fa-duotone fa-circle-check" : "icon-nodesc iwork fa-duotone fa-person-digging"
                }
            }
        }
        const statusUpdate = () => {
            translations.value.map (e => {
                e.finished = (e.target.text || "").length > 0
                if (props.hasDescription && e.source.description) {
                    e.finished = e.finished && ((e.target.description || "").length > 0)
                }
            })
            emit ("update:modelValue", translations.value)
        }
        const checkTranslations = (tr, kind) => {
            const placeholders = new RegExp ('{{ *([a-zA-Z0-9_]+) *}}')
            const srcPL        = {}
            tr.source [kind].replace (placeholders, function (m, g1) {
                srcPL [g1] = "S"
            })
            tr.target [kind].replace (placeholders, function (m, g1) {
                if (srcPL [g1] == "S")
                    delete srcPL [g1]
                else 
                    srcPL [g1] = "T"
            })
            let hint = []
            for (const [key, value] of Object.entries (srcPL)) {
                if (value == "S") {
                    hint.push (`Placeholder ${key} not found in translation`)
                }
                if (value == "T") {
                    hint.push (`Placeholder ${key} found in translation but not in source`)
                }
            }
            if (hint.length) {
                tr.error_hint = hint.join (", ")
            } else {
                delete tr.error_hint
            }
        }
        return {translations, classes, displayTr, statusUpdate, checkTranslations}
    }
}
</script>

<style lang="scss" scoped>
    .translation-table {
        .error {
            color: #f44336;
        }
        .done, .done .p-inputtext {
            background-color: rgba(191, 240, 186, 0.664);
        }
        .idone {
            color: green;
        }
        .iwork {
            color: red;
        }
        .icon-nodesc {
            display:        inline-block;
            width:          1rem;
            margin-right:   1rem;
            vertical-align: top;
        }
        .stext-nodesc {
            width:      35%;
            display:    inline-block;
            vertical-align: top;
        }
        .dtext-nodesc {
            width:      60%;
            display:    inline-block;

            .p-inputtext {
                width: 100%;
            }

        }
        .stext {
            width:      35%;
            display:    inline-block;
        }
        .dtext {
            width:      60%;
            display:    inline-block;

            .p-inputtext {
                width: 100%;
            }
        }
        .sdescription {
            width:      35%;
            display:    inline-block;
        }
        .ddescription {
            width:      60%;
            display:    inline-block;

            .p-inputtextarea {
                width:      100%;
                padding:    0;
                height:     2.2rem;
            }
        }
        .odd {
            background-color:   #F8F8F8;
            .p-inputtext {
                background-color: #F8F8F8;
            }
        }
        .even, .odd {
            padding:            5px 0;
            border-bottom:      1px solid black;
        }
    }
</style>