<template>
    <Card>
        <template #title>
            Translation {{ user }}
        </template>
        <template #subtitle>
            <label>Source Language</label> 
            <Dropdown :options="state.sources" v-model="state.source" optionLabel="name" optionValue="code" />
            <label>Target Language</label>
            <Dropdown :options="state.targets" v-model="state.target" optionLabel="name" optionValue="code" />
        </template>
        <template #content v-if="! state.loading">
            <div class="table-header">
                <div class="p-formgroup-inline">
                    <div class="p-field">
                        <label >Filter by Status</label>
                        <Dropdown v-model="state.filterStatus" :options="filterStatusOptions" 
                            optionLabel="name" optionValue="value" style="width: 10rem"
                        />
                    </div>
                    <div class="p-field">
                        <label >Search text</label>
                        <InputText v-model="state.filterText" />
                    </div>
                </div>
                <div>{{ missing }} of {{ total }} translations missing</div>
                <Button icon="pi pi-save" @click="saveTranslations" :loading="state.saving" />
            </div>
            <TabView lazy>
                <TabPanel :header="'Application Text (' + missingApplication + ')'">
                    <TranslationTable v-model="state.translations" :has-description="false" 
                        :filter-status="state.filterStatus"
                        :filter-text="state.filterText"
                    />
                </TabPanel>
                <TabPanel :header="'Features (' + missingFeatures + ')'">
                    <TranslationTable v-model="state.features" :has-description="false" 
                        :filter-status="state.filterStatus"
                        :filter-text="state.filterText"
                    />
                </TabPanel>
                <TabPanel :header="'Status Messages (' + missingStatusCode + ')'">
                    <TranslationTable v-model="state.status_codes" :has-description="true" 
                        :filter-status="state.filterStatus"
                        :filter-text="state.filterText"
                    />
                </TabPanel>
                <TabPanel :header="'Data Center (' + missingDataCenter + ')'">
                    <TranslationTable v-model="state.data_center" :has-description="false" 
                        :filter-status="state.filterStatus"
                        :filter-text="state.filterText"
                    />
                </TabPanel>
                <TabPanel :header="'Models (' + missingUidTrs + ')'">
                    <ModelTranslation 
                        v-model="state.uid_trs"
                        :modules="state.modules"
                        :filter-status="state.filterStatus"
                        :filter-text="state.filterText"
                    />
                </TabPanel>
            </TabView>
        </template>
    </Card>
</template>

<script>
import { reactive, onBeforeMount, watch, computed }              from 'vue'
//import { useRouter }            from 'vue-router'
import { useToast }             from 'primevue/usetoast'
import Card                             from 'primevue/card'
import Dropdown                         from 'primevue/dropdown'
import TabView                          from 'primevue/tabview'
import TabPanel                         from 'primevue/tabpanel'
import InputText                        from 'primevue/inputtext'
import TranslationTable                 from '@/components/isew/translation/TranslationTable'
import ModelTranslation                 from '@/components/isew/translation/ModelTranslation'
import translation                      from "@/api/translation"
import auth                             from "@/store/auth"

export default {
    components: {Card, Dropdown, InputText, TranslationTable, ModelTranslation,
        TabView, TabPanel
        },
    setup () {
		const toast = useToast()
        let state = reactive ({sources : [], targets : [], source : "en", target : "",
            has_description: true,
            saving: false,
            loading: true,
            filterMode: false,
            translations: [],
            uid_trs: {},
            modules: [],
            status_codes: [],
            features: [],
            data_center: [],
            expandedModules: [],
            expandedPara: [],

            filterStatus: 'untranslated',
            filterText: '',
            })
        let email = auth.getAuthState().user.email
        onBeforeMount (async () => {
            const r = await translation.loadLanguages (email)
            if (r.status) {
                state.sources = r.sources
                state.targets = r.targets
            }
        })
        watch (() => [state.target, state.source], async () => {
            state.loading = true
            if (state.source && state.target) {
                const resp = await translation.loadTranslations (state.source, state.target)
                if (resp.status) {
                    state.has_description   = resp.has_description
                    state.translations      = resp.translations
                    state.uid_trs           = resp.uid_trs
                    state.modules           = resp.modules
                    state.status_codes      = resp.status_codes
                    state.features          = resp.features
                    state.data_center       = resp.data_center
                }
                state.loading = false
            } else {
                state.translations = []
            }
        })
        const total = computed (() => {
            return state.translations.length 
                 + Object.keys (state.uid_trs).length
                 + state.status_codes.length
                 + state.features.length
        })
        const missingApplication = computed (() => {
            return state.translations.filter (tr => tr.finished != true).length
        })
        const missingUidTrs = computed (() => {
            return Object.values (state.uid_trs).filter (tr => tr.finished != true).length
        })
        const missingFeatures = computed (() => {
            return state.features.filter (tr => tr.finished != true).length
        })
        const missingStatusCode = computed (() => {
            const mi = state.status_codes.filter (tr => tr.finished != true)
            console.log (mi)
            return mi.length
        })
        const missingDataCenter = computed (() => {
            return state.data_center.filter (tr => tr.finished != true).length
        })
        const missing = computed (() => {
            return missingApplication.value + missingUidTrs.value + missingFeatures.value + missingStatusCode.value + missingDataCenter.value
        })
        const saveTranslations = async () => {
            //state.saving = true
            await translation.saveTranslations (
                state.translations, state.uid_trs, state.status_codes, state.features, state.data_center, state.target)
            state.translations.map ((e) => [
                e.hide = e.finished
            ])
            state.saving = false
            toast.add({severity: 'info', 
                summary: 'Success', 
                detail: `Translation successfully saved` , life: 3000})
        }
        const filterStatusOptions = [
            {name: 'All',           value: 'all'},
            {name: 'Translated',    value: 'translated'},
            {name: 'Untranslated',  value: 'untranslated'}
        ]
        return {state, user: email, 
            missing, missingApplication, missingUidTrs, missingFeatures, missingStatusCode, missingDataCenter,
            total, saveTranslations, 
            filterStatusOptions
            }
    }
}
</script>

<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
    padding-top: 0;
    padding-bottom: 0;
}
::v-deep(.unfinished) {
    background-color: rgba(255, 222, 222, 0.36) !important;
}
::v-deep(.unfinished-parameter) {
    background-color: rgba(221, 98, 98, 0.363) !important;
}
::v-deep(.unfinished-choices) {
    background-color: rgba(255, 64, 64, 0.36) !important;
}
.module-header label {
    width:        7em;
    margin-left:  1em;
    margin-right: 1em;
    vertical-align: top;
}
.module-header input {
    vertical-align: top;
}
.module-header span {
    vertical-align: top;
    margin-left:    1em;
    font-style:     italic;
}
.description {
    height: 2.5rem;
    width:  25rem;
}
::v-deep(.no-expand button) {
    display: none;
}

td input {
    width: 100%;
}
label {
    display:    inline-block;
    width:      10rem;
}
</style>