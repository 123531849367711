<template>
        <ObjectEdit
            modelName="Project"
            id=""
            formName="default"
            asNew="true"
            toolbar="bottom"
            buttons=""
            :useRouter="false"
            @edit-finished="done"
        />
</template>

<script>
import ObjectEdit           from '@/components/models/ObjectEdit'
import { useRouter }            from 'vue-router'

export default {
    components: {ObjectEdit},
    setup () {
        const router  = useRouter  ()
        const done = ({action, object}) => {
            console.log (action, object)
            if (action == "cancel") {
                router.push ({name: "dashboard"})
            } else {
            console.log (action, object)

            }
        }
        return {done}
    }
}
</script>

<style>

</style>