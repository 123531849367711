<template>
  <div id="activate-account-view">
    <h1>Verify Email</h1>
    <template v-if="activationLoading">loading...</template>
    <template v-else-if="activationError">An error occured.</template>
    <template v-else-if="activationCompleted">
      Account activation successful.
      <router-link v-if="!isAuthenticated" to="/login">
        Click here to sign in.
      </router-link>
    </template>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import Auth         from "@/store/auth"

export default {
    async setup () {
        const route  = useRoute  ()
        //const router = useRouter ()
        await Auth.activateAccount (route.params).then (() => {
          window.location.href = "/auth/login"
        })
    }
}
</script>
