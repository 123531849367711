<template>
    <div ref="masterContainer" style="height: 75vh">
        <Splitter>
            <SplitterPanel >
                <DataTable
                    :value="vModels"
                    selectionMode="single"
                    dataKey="0"
                    v-model:selection="state.currentModule"
                    @row-select="showInstances"
                    sortField="module" :sortOrder="1"
                    scrollable :scrollHeight="scrollHeight"
                    >
                    <Column name="module" header="Module" field="1" :sortable="true" />
                </DataTable>
            </SplitterPanel>
            <SplitterPanel>
                <DataTable :value="state.instances" v-if="state.currentModule.length" :loading="state.loading" :scrollable="true">
                    <Column field="name">
                        <template #header>
                            Objects of {{ state.currentModule [1] }}
                            <Button icon="fa-regular fa-circle-plus" class="p-button-rounded p-button-link" @click="newObject" />
                        </template>
                    </Column>
                    <Column headerStyle="width: 75px">
                        <template #body="props">
                            <Button icon="fa-regular fa-pencil" class="p-button-rounded p-button-text"                  @click="editObject(props.data)" />
                            <Button icon="fa-regular fa-copy"   class="p-button-rounded p-button-text p-button-warning" @click="copyObject(props.data)" />
                            <Button icon="fa-regular fa-trash"  class="p-button-rounded p-button-text p-button-danger"  @click="deleteObject(props.data)" />
                            <Button v-if="state.currentModule [1] == 'Project Settings' && (aState.user.groups.MODEL_EDIT_PROJECT_SETTINGS || aState.user.groups.PROJECT_CHANGE_VALUES)"
                              icon="fa-regular fa-toolbox"  
                              class="p-button-rounded p-button-text p-button-warning"  
                              @click="projectSettings(props.data)" 
                            ></Button> 
                        </template>
                    </Column>
                </DataTable>
            </SplitterPanel>
        </Splitter>
        <ConfirmDialog />
        <Dialog header="Copy" v-model:visible="state.showCopyVisible" style="width: 50vw">
            <h3>Please select to which project you want to copy {{state.sourceObject.name}}</h3>
            <div class="p-field-checkbox" v-for="puh in state.possibleDestinations" :key="puh.id">
                <Checkbox :id="`puh_${puh.id}`" name="destination" :value="puh.id" v-model="state.destinations" />
                <label :for="`puh_${puh.id}`">{{ puh.project__name}} {{puh.hardware__name}} ({{ puh.name }})</label>
            </div>

            <div class="p-field-checkbox">
                <label for="new_name">Select a new name</label>&nbsp;
                <InputText id="new_name" v-model="state.newName" />
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="state.showCopyVisible = false" class="p-button-text"/>
                <Button label="Copy" icon="pi pi-check" @click="copyObjectToDestinations" autofocus 
                    :disabled="state.destinations.length == 0 || state.newName.length == 0"
                />
            </template>
        </Dialog>
    </div>
</template>

<script>
//import { reactive, computed, unref } from 'vue'
import { ref, reactive, watch, toRefs, computed, unref, onMounted, onBeforeUnmount }  from 'vue'
import auth                         from "@/store/auth"
import {projects }                  from "@/api/projects"
import models                       from '@/api/models'
import DataTable                    from 'primevue/datatable'
import Column                       from 'primevue/column'
import Splitter                     from 'primevue/splitter'
import SplitterPanel                from 'primevue/splitterpanel'//import { useConfirm }       from "primevue/useconfirm";
import Dialog                       from 'primevue/dialog';
import Checkbox                     from 'primevue/checkbox';
import InputText                    from 'primevue/inputtext';
import ConfirmDialog                from 'primevue/confirmdialog';
import { useConfirm }               from "primevue/useconfirm";
import { useToast }                 from "primevue/usetoast";

function useSizeObserver (elRef) {
    const widthRef  = ref (10)
    const heightRef = ref (10)
    const resizeObserver = new ResizeObserver (entries => {
      for (let entry of entries) {
        widthRef.value  = entry.contentRect.width
        heightRef.value = entry.contentRect.height
      }
    })
    onMounted      (() => resizeObserver.observe  (elRef.value));
    onBeforeUnmount(() => resizeObserver.unobserve(elRef.value));
    return {
      widthRef,
      heightRef
    };
  }

export default {
    components: {DataTable, Column, Splitter, SplitterPanel, ConfirmDialog, Dialog, Checkbox, InputText},
    emits: ["edit-instance"],
    props: {
        puhId:          {type: Number,  required: true},
        models:         {type: Array,   required: true},
        changeInstance: {type: Object}
    },
    setup (props, {emit}) {
        const masterContainer           = ref(null);
        const {heightRef}           = useSizeObserver (masterContainer);
        const { changeInstance } = toRefs(props)
        const confirm = useConfirm ()
        const toast = useToast()
        const state   = reactive ({
            loading: false,
            currentModule: [],
            formSpec: {},
            instances: [],
            possibleDestinations: [],
            destinations: [],
            sourceObject: null,
            showCopyVisible: false,
            newName: "",
        })
        const aState = auth.getAuthState ()
        const showInstances = async () => {
            state.loading = true
            const resp = (await projects.loadProjectUsesHardwareInstances (
                props.puhId, state.currentModule [0])).data
            if (resp.status) {
                state.instances = resp.instances
                state.formSpec  = resp.formSpec
                state.possibleDestinations = resp.possible_destinations
            }
            state.loading = false
        }
        const editObject = (obj) => {
            emit ("edit-instance", {object: obj, spec: state.formSpec})
        }
        const copyObject = (obj) => {
            state.sourceObject    = obj
            state.newName         = obj.name
            state.showCopyVisible = true
        }
        const copyObjectToDestinations = async () => {
            await projects.copyObjectToProjects (state.sourceObject.pk, unref (state.destinations), state.newName)
            state.showCopyVisible = false
        }
        const deleteObject = (obj) => {
            confirm.require({
                message: 'Are you sure you want to delete this object?',
                header: 'Confirmation',
                icon: 'fa-duotone fa-exclamation-triangle',
                acceptIcon: "fa-solid fa-trash",
                acceptLabel: "Yes",
                rejectIcon: "fa-solid fa-times",
                accept: async () => {
                    const resp = await models.deleteObject ("Module_Instance", obj.pk)

                    if (resp.data.status !== true) {
                        //emit ("error-message", result)
                    } else {
                        const idx = state.instances.indexOf (obj)
                        state.instances.splice (idx, 1)
                    }
                },
            })
        }
        watch (changeInstance, (nV) => {
            let found = false
            for (let obj of state.instances) {
                if (obj.pk == 0 || obj.pk == nV.pk) {
                    obj.pk   = nV.pk
                    obj.name = nV.name
                    found = true
                    break
                }
            }
            if (!found) {
                state.instances.push (nV)
            }
        })
        const prjSetRigh = aState.user.groups.MODEL_EDIT_PROJECT_SETTINGS == true
        const vModels = computed (() => {
            return props.models.filter ((m) => {
                return !prjSetRigh || (m [1] == "Project Settings")
            })
        })
        const newObject = () => {
            emit ("edit-instance", {
                object: {pk: 0, mpk: state.currentModule [0]},
                spec: state.formSpec})

        }
        const projectList = computed (() => {
            const result = []
            state.possibleDestinations.forEach ((puh) => {
                //if (1 || puh.id != state.projectId) {
                    result.push (puh)
                //}
            })
            return result
        })
        const scrollHeight = computed (() => {
            return heightRef.value + "px";
        })
        const projectSettings = async (data) => {
            state.downloadCode = true
            try {
                await projects.downloadProjectSettings (data.pk)
                toast.add({severity:'info', summary: 'Project Settings Download successfull', life: 3000});
                state.downloadCode = false
            } catch (err) {
                toast.add({severity:'error', summary: 'Project Settings Download failure', detail:err.toString (), life: 10000});
                state.downloadCode = false
            }
        }
        return {state, aState, showInstances, vModels,
            copyObject, editObject, deleteObject, newObject, 
            projectList, copyObjectToDestinations,
            masterContainer, scrollHeight, projectSettings
        }
    }
}

</script>

<style>
</style>