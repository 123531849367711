import api                                      from '@/api/models'
import { reactive, onBeforeMount }              from 'vue'
import { onBeforeRouteUpdate  }                 from 'vue-router'

const state = reactive ({
    models  : {},
    error   : null,
    state   : "",
    objects: {}
})
const getModelInner = async (modelName) => {
    if (state.models [modelName]) {
        return {model: state.models [modelName]}
    }
    state.state = "loading"
    return api.loadModel (modelName)
        .then (({ data }) => {
            state.state = ""
            if (data.status) {
                const model = data.model
                model.computed          = {}
                for (const [key, value] of Object.entries (model.fields)) {
                    let c = value.computed
                    if (c) {
                        if (typeof c.get === "string") eval (`c.get = ${c.get}`)
                        if (typeof c.set === "string") eval (`c.set = ${c.set}`)
                        c.source             = value.source
                        model.computed [key] = c
                    }
                }
                state.models  [modelName] = model
                if (!state.objects [modelName])
                    state.objects [modelName] = []
                return {model: model}
            } else {
                state.error = data.message
                state.state = "error"
                return {error : state.error}
            }
        }).catch ((err) => {
            state.state = "error"
            state.error = err.response.data
            return {error : state.error}
        })
}
const _mergeObjects = (modelName, objects, pk) => {
    const key2Index = {}
    for (const idx in state.objects [modelName]) {
        key2Index [state.objects [modelName] [pk]]  = idx
    }
    for (const o of objects) {
        const idx = key2Index [o [pk]]
        if (idx === undefined) {
            state.objects [modelName] = [...state.objects [modelName], o]
        }
    }
    return state.objects [modelName]
}
const addObject = (modelName, object, pk) => {
    if (!state.objects [modelName]) state.objects [modelName] = []
    _mergeObjects (modelName, [object], pk)
}
const updateComputed = (model, objects) => {
    if (model.computed) {
        for (let o of objects) {
            for (let fname in model.computed) {
                const comp = model.computed [fname]
                o [fname]  = comp.get (o [comp.source])
            }
        }
    }
    return objects
}
const loadObjects = async (modelName) => {
    console.log ("Load objects for", modelName)
    const {model} = await getModelInner (modelName)
    if ((state.objects [modelName] || []).length > 0) {
        return {objects: state.objects [modelName]}
    }
    state.state = "loading"
    return api.loadObjects (modelName)
        .then (({ data }) => {
            if (data.status) {
                _mergeObjects (modelName,
                    updateComputed (state.models [modelName], data.objects),
                    model.form.pk)
                return {objects: state.objects [modelName]}
            } else {
                return {error: data.error}
            }
        }).catch ((data) => {
            return {error: data}})
}
const getObject = async (modelName, id, asNew) => {
    id = Number (id)
    const {model}   = await getModelInner (modelName) // load the model definition
    const {objects} = await loadObjects (modelName)
    const pkField = model.form.pk
    if (id <= 0) { // request the defaults for the this object type
        return api.defaults (modelName, id).then (({data}) => {
            if (data.status) {
                const minPk   = Math.min (0,
                    ...objects.map ((o) => o [pkField]))
                data.object [pkField] = minPk - 1
                return data.object
            } else {
                return data.error
            }
        }).catch (data => {
            return {error: data}
        })
    } else {
        for (const obj of objects || []) {
            if (obj.id == id) {
                if (asNew == "true") {
                    const minPk   = Math.min (0,
                        ...objects.map ((o) => o [pkField]))
                    const object = {...obj}
                    object [pkField] = minPk - 1
                    return object
                }
                return obj
            }
        }
    }
    return {error: `Object ${modelName} with id ${id} not found`}
}
function setupModelLoading (props, state) {
    const getModel = async (modelName) => {
        if (modelName === undefined) modelName = props.modelName
        state.loading = true
        getModelInner (modelName).then (({model, error}) => {
            if (model)
                state.model = model
            else if (error) {
                state.message = {kind: "error", message: error}
            }
            state.loading = false
        })
    }
    //const route = useRoute ()
    onBeforeMount (getModel)
    onBeforeRouteUpdate(async (to) => {
        await getModel (to.params.modelName)
    })
}
const setupGetObject = async (props, state) => {
    onBeforeMount (async () => {
        state.loading = true
        const res     = await getModelInner (props.modelName)
        state.model   = res.model
        state.object  = await getObject     (props.modelName, props.id, props.asNew)
        if (props.overrides) {
            for (const [key, value] of Object.entries(props.overrides)) {
                state.object [key] = value
            }
        }
        state.loading = false
    })
    onBeforeRouteUpdate(async (to) => {
        state.loading = true
        const res     = await getModelInner (props.modelName)
        state.model   = res.model
        state.object  = await getObject     (to.params.modelName, to.params.id, to.params.asNew)
        state.loading = false
    })
}
const deleteObject = async (object, modelName) => {
    const {model} = await getModelInner (modelName)
    let objects   = (await loadObjects (modelName)).objects || []
    const pk      = object [model.form.pk]
    const i = objects.map (e => e.id).indexOf (pk)
    if (i < 0)
        return "Object not in list"
    if (! pk || pk < 0) {
        objects.splice (i, 1)
        return true
    }
    return api.deleteObject (modelName, pk)
        .then (({ data }) => {
            if (data.status) {
                objects.splice (i, 1)
                return true
            } else {
                return data.message
            }
        }).catch ((data) => {
            return data
        })
}
const saveObject = async (modelName, pk, data) => {
    const {model}   = await getModelInner (modelName)
    const {objects} = await loadObjects (modelName)
    if (pk > 0) {
        return await api.saveObject (modelName, pk, data
            ).then (({data}) => {
                if (data.status === false)
                    return {message : data.message, errors: data.errors}
                if (model.computed) {
                    for (let fname in model.computed) {
                        const comp = model.computed [fname]
                        data.object [fname]  = comp.get (
                            data.object [comp.source])
                    }
                }
                const i = objects.map (e => e.id).indexOf (pk)
                objects [i] = data.object
                return {status: true, object: data.object}
            })
    } else {
        return await api.newObject (modelName, data
            ).then (({data}) => {
                if (data.status === false)
                    return {message : data.message, errors: data.errors}
                if (model.computed) {
                    for (let fname in model.computed) {
                        const comp = model.computed [fname]
                        data.object [fname]  = comp.get (
                            data.object [comp.source])
                    }
                }
                objects.push (data.object)
                return {status: true, object: data.object}
            })

    }
}
const revertObject = (object, initial) => {
    for (const [key, value] of Object.entries (initial)) {
        object [key] = value
    }
}
const copyObject = (source, pkField) => {
    let result = {}
    for (const [key, value] of Object.entries (source)) {
        if (key != pkField)
            result [key] = value
    }
    return result
}
export default { loadObjects, setupModelLoading, setupGetObject, getModelInner
               , deleteObject, saveObject, getObject, addObject
               , revertObject, copyObject
               }