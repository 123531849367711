<template>
    <span>
        {{ modelValue.length }} 
        <Button icon="fa-regular fa-plus" 
                class="p-button-sm p-button-warning" label="Add" 
                style="margin-left: 2em" 
                @click="addNewInstance" />
    </span>
</template>

<script>
import projects from  '@/api/projects'

export default {
    props: {
        pk:         {required: true},
        modelValue: {type: [Object, Number], required: true},
        forms:      {type: Array,            required: true},
        projectId:  {type: Number,  required: true}
    },
    emits: ["add-instance"],
    setup (props, {emit}) {
        const addNewInstaneOf = async (uid) => {
            const ms = props.forms.filter ((s) => s.module == uid) [0]
            let inst = {}
            for (let w of ms.widgets) {
                if (w.varType.id == 243) {// module selection
                    let v = {value: 0, text: null, options: {references: []}}
                    const resp = await projects.loadReferences (props.projectId, w.default)
                    if (resp.status == 200 && resp.data.status) {
                        v.options.references = resp.data.references
                    }
                    inst [w.pk] = v
                } else if (w.varType.id == 244) {// Inline List
                    inst [w.pk] = {value: [], text: null}
                } else {
                    inst [w.pk] = {"value": w.default}
                }
            }
            inst ["MUID"] ["name"] = ms.name
            inst ["open"]          = true
            emit ("add-instance", {pk: props.pk, instance: inst})
        }
        const addNewInstance = async () => {
            const modList = props.forms
            if (modList.length > 1) {
                this.showModuleSelect = true
            } else {
                await addNewInstaneOf (modList [0].module)
            }
        }
        return {addNewInstance}
    }
}
</script>

<style>

</style>