<template>
<div>
    <ObjectEdit
        modelName="Project"
        :id="id.toString ()"
        formName="default"
        asNew="false"
        toolbar="bottom"
        :useRouter="false"
        @edit-finished="done"
        :object="state.object"
        v-if="showEdit"
    />
</div>
</template>

<script>
import { reactive }             from 'vue'
import ObjectEdit               from '@/components/models/ObjectEdit'
import { useRouter }            from 'vue-router'
import models                   from '@/store/models'
import auth                     from "@/store/auth"

export default {
    props: {
        id:        {type: Number, required: true},
        modelName: {type: String, default: "Project"},
        asNew:     {type: String, default: "false"},
    },
    setup (props) {
        const state = reactive ({
            loading : props.loading,
            model:    props.model,
            object:   null,
        })
        const router  = useRouter  ()
        const aState  = auth.getAuthState ()
        const done = ({action, object}) => {
            console.log (action, object)
            if (action == "cancel") {
                router.push ({name: "dashboard"})
            } else if (action == "delete") {
            console.log (action, object)
            } else {
            console.log (action, object)
            }
        }
        models.setupGetObject    (props, state)
        return {done, state, showEdit: aState.user.groups.MODEL_EDIT_PROJECT_SETTINGS != true}
    },
    components: {ObjectEdit}
}
</script>

<style>
</style>