<template>
    <Card>
        <template #title>
            <Breadcrumb :model="state.fullPath" :home="bcHome">
            </Breadcrumb>
        </template>
        <template #content>
        <DataTable
            :value="state.generations"
            :loading="state.loading"
            v-model:expandedRows="state.expandedRows"
            @row-expand="loadDetails"
        >
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column header="Kind">
                <template #body="props">
                    {{ generationOwnerKind(props.data) }}
                </template>
            </Column>
            <Column header="Owner">
                <template #body="props">
                    {{ generationOwner(props.data) }}
                </template>
            </Column>
            <Column>
                <template #header>
                    <Button icon="fa-solid fa-download" class="p-button-text p-button-success"
                        v-tooltip.top="'Export all License Keys'"
                        @click="getKeys()"
                    />
                    <Button icon="fa-solid fa-rabbit-running" class="p-button-text p-button-danger"
                        v-tooltip.top="'Generate all License Keys'"
                        v-if="lkManager"
                        @click="generateKeys()"
                    />
                    <Button icon="fa-solid fa-plus" class="p-button-text"
                        v-tooltip.top="'Create new Licence Keys'"
                        @click="newLicenceGeneration"
                        v-if="lkManager"
                    />
                </template>
                <template #body="props">
                    <Button icon="fa-solid fa-download" class="p-button-text p-button-success"
                        v-tooltip.top="'Export License Keys from this generation'"
                        @click="getKeys(props.data)"
                    />
                    <Button icon="fa-solid fa-rabbit-running" class="p-button-text p-button-danger"
                        v-tooltip.top="'Generate all License Keys'"
                        v-if="lkManager"
                        @click="generateKeys(props.data)"
                    />
                </template>
            </Column>
            <template #expansion="props">
                <Chip class="custom-chip" v-for="(f, idx) in props.data.features" :key="idx" :label="f.name" />
                <!--<DataTable
                <DataView
                    :value="props.data.keys"
                    columnResizeMode="fit" showGridlines
                >
                    <Column header="Unique Number" headerStyle="width: 20em">
                        <template #body="kprops">
                            {{ kprops.data.unique_number }}
                        </template>
                    </Column>
                    <Column>
                        <template #body="kprops">
                            {{ kprops.data.for_ecu [1]}}
                        </template>
                    </Column>
                </DataTable>
                -->
                <DataView
                    :value="props.data.ecus" :layout="state.layout" class="ecu-panel"
                >
                    <template #header>
                        <div class="p-grid p-nogutter">
                            <div class="p-col-6" style="text-align: left">
                                <!--
                                Ecu in this group
                                    <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Sort By Price" @change="onSortChange($event)"/>
                            </div>
                            <div class="p-col-6" style="text-align: right">
                                -->
                                <DataViewLayoutOptions v-model="state.layout" />
                            </div>
                        </div>
                    </template>
                    <template #list="kprops">
                        <div class="p-col-4">
                            {{ kprops.data.name }} ({{ kprops.data.serial_number }})
                        </div>
                        <div class="p-col-4">
                            {{ kprops.data.unique_number }}
                        </div>
                        <div class="p-col-3">
                                <div v-if="kprops.data.key">{{ formatKeyNumber (kprops.data.key) }}</div>
                                <div v-else>No key generated for this ECU</div>
                        </div>
                        <div class="p-col-1">
                            <Button icon="fa-solid fa-download" class="p-button-text p-button-success"
                                v-tooltip.top="'Export License Keys for this ECU'"
                                v-if="kprops.data.key"
                                @click="getKeys(props.data, kprops.data)"
                            />
                            <Button icon="fa-solid fa-rabbit-running" class="p-button-text p-button-danger"
                                v-tooltip.top="'Generate Licence Key'"
                                v-if="!kprops.data.key"
                                @click="generateKeys(props.data, kprops.data)"
                            />
                        </div>
                    </template>
                    <template #grid="kprops">
                        <div style="padding: .5em" class="p-col-12 p-md-4">
                            <Panel :header="props.data.vin" style="text-align: center">
                                <template #header>
                                    <Button icon="fa-solid fa-download" class="p-button-text p-button-success"
                                        v-tooltip.top="'Export License Keys for this ECU'"
                                        v-if="kprops.data.key"
                                        @click="getKeys(props.data, kprops.data)"
                                    />
                                    <Button icon="fa-solid fa-rabbit-running" class="p-button-text p-button-danger"
                                        v-tooltip.top="'Generate Licence Key'"
                                        v-if="!kprops.data.key"
                                        @click="generateKeys(props.data, kprops.data)"
                                    />
                                    {{ kprops.data.name }} ({{ kprops.data.serial_number }})
                                </template>
                                {{ kprops.data.unique_number }}
                                <div v-if="kprops.data.key">License key Number: {{ formatKeyNumber (kprops.data.key) }}</div>
                                <div v-else>No key generated for this ECU</div>
                            </Panel>
                        </div>
                    </template>
                </DataView>
            </template>
        </DataTable>
        </template>
    </Card>
    <Dialog v-model:visible="state.newGeneration">
        <template #header>
            Select the Features for the new Licence Keys
        </template>
            <div class="p-fluid">
                <div class="p-field p-grid">
                    <label for="key_type" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Licence Key Type</label>
                    <div class="p-col-12 p-md-10">
                        <Dropdown id="key_type" v-model="state.keyType" :options="state.keyTypes" optionValue="value" optionLabel="name" />
                    </div>
                </div>
            </div>
        <PickList v-model="state.selectedFeatures"
                  dataKey="id"
                  listStyle="height:342px; width: 20em"
        >
            <template #sourceHeader>
                Available
            </template>
            <template #targetHeader>
                Selected
            </template>
            <template #item="props">
                <div>{{ props.item.name}}</div>
            </template>
        </PickList>
        <template #footer>
            <Button label="Cancel" icon="fa-solid fa-xmark" class="p-button-text" autofocus @click="state.newGeneration = false" />
            <Button label="Save" icon="fa-solid fa-check" @click="generateNew" />
        </template>
    </Dialog>
</template>

<script>
import { onBeforeMount, reactive, computed }        from 'vue'
import { useToast }             from "primevue/usetoast"
import isew                     from '@/api/isew'
import auth                     from "@/store/auth"
import Breadcrumb               from 'primevue/breadcrumb'
import DataView                 from 'primevue/dataview'
import DataViewLayoutOptions    from "primevue/dataviewlayoutoptions"
import DataTable                from 'primevue/datatable'
import Dropdown                 from 'primevue/dropdown';
import Column                   from 'primevue/column'
import Panel                    from 'primevue/panel'
import Chip                     from 'primevue/chip'
import Card                     from 'primevue/card'
import Dialog                   from 'primevue/dialog'
import PickList                 from 'primevue/picklist'
export default {
    props: {
        type: {type: String, required: true},
        id:   {type: Number, required: true}
    },
    setup (props) {
        const toast = useToast ()
        const aState = auth.getAuthState ()
        const lkManager = computed (() => aState.user.groups.LICENCE_KEY_MANAGEMENT == true)
        const state = reactive ({generations: [],
            keyTypes: [],
            keyType: "D7118E08",
            loading: true,
            expandedRows: [],
            fullPath: [],
            layout: "grid",
            selectedFeatures: [[], []],
            newGeneration: false})
        onBeforeMount (async () => {
            const lkData = await isew.loadLicenceKeys (props.type, props.id)
            state.generations          = lkData.generations
            state.fullPath             = lkData.full_path
            const data = await isew.loadFeatures    ()
            state.selectedFeatures [0] = data.features
            state.keyTypes             = data.licence_key_types
            state.loading              = false
        })
        const generationOwner = (gen) => {
            /*if (gen.customer && gen.customer [0]) return gen.customer [1]
            if (gen.group    && gen.group    [0]) return gen.group    [1]
            if (gen.group    && gen.group    [0]) return gen.ecu      [1]
            return "?"
            */
           const p = []
           for (const pp of gen.fullPath) p.push (pp.label)
           return p.join (" / ")
        }
        const generationOwnerKind = (gen) => {
            if (gen.customer && gen.customer [0]) return "Customer"
            if (gen.group    && gen.group    [0]) return "Group"
            if (gen.group    && gen.group    [0]) return "ECU"
            return "?"
        }
        const loadDetails = async (event) => {
            const resp = await isew.loadGenerationDetails (event.data.id)
            let missing = false
            for (const ecu of resp.ecus) {
                ecu.key = resp.keys [ecu.id]
                if (!ecu.key) {
                    missing = true
                    console.log ("Key missing for", ecu)
                }
            }
            event.data.features     = resp.features
            event.data.ecus         = resp.ecus
            event.data.missingKeys  = missing
        }
        const getEcuName = (fn) => {
            const split = fn.split (" ")
            return split.splice (0, split.length - 1).join (" ")
        }
        const getEcuNumber = (fn) => {
            const split = fn.split (" ")
            return split [split.length - 1]
        }
        const newLicenceGeneration = () => {
            state.newGeneration = true
        }
        const generateNew = async () => {
            const resp = await isew.newGeneration (props.type, props.id, state.keyType, state.selectedFeatures [1])
            state.generations = [...state.generations, resp.generation]
            state.newGeneration = false
        }
        const generateKeys = async (lkg, ecu) => {
            let root = "Licence_Key_Generation"
            if (!lkg) root = props.type
            let id = lkg ? lkg.id : props.id
            const resp = await isew.generateKey (root, id, ecu && ecu.id)
            if (ecu) {
                ecu.key = resp.key
            } else {
                if (lkg) {
                    for (const ecu of lkg.ecus) {
                        ecu.key = resp.keys [ecu.id]
                    }
                } else {
                    for (const lkg of state.generations) {
                        for (const ecu of lkg.ecus) {
                            ecu.key = resp.keys [ecu.id]
                        }
                    }
                }
            }
            toast.add({severity:'success', summary: 'Keys generated', detail:`${resp.created} keys generated`, life: 3000});
        }
        const getKeys = async (lkg, ecu) => {
            let root = "Licence_Key_Generation"
            if (!lkg) root = props.type
            let id = lkg ? lkg.id : props.id
            await isew.getKey (root, id, ecu && ecu.id)
        }
        const formatKeyNumber = (key) => {
            return key.unique_number_hex
        }
        const bcHome = {label: "Licence Key Generations", to:"/customers"}
        return {lkManager, state, generationOwnerKind, generationOwner,
            loadDetails, getEcuName, getEcuNumber, bcHome,
            newLicenceGeneration, generateNew, generateKeys, getKeys,
            formatKeyNumber}
    },
    components: {DataView, DataViewLayoutOptions, DataTable, Column,
    Panel, Chip, Card, PickList, Dialog, Dropdown, Breadcrumb}
}
</script>

<style lang="scss" scoped>
.p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
    margin-right: 10px;
}
.ecu-panel {
    margin-top: 1em;
}
</style>