<template>
    <div class="p-grid p-jc-center full-height">
        <Card class="p-col-3">
            <template #title>Create Account</template>
            <template #content>
                <template v-if="regState.createState == 'processing'">
                    loading...
                </template>
                <template v-else-if="regState.createState == 'input'">
                    <form class="p-fluid" @submit.prevent="submit">
                        <ErrorList title="A error occured while processing your request." :errors="regState.errors.non_field_errors"/>
                        <FormField field-name="username" label="Username" :errors="regState.errors.username">
                            <InputText id="username" type="text" v-model="inputs.username" />
                        </FormField>
                        <FormField field-name="email" label="E-Mail" :errors="regState.errors.email">
                            <InputText id="email" type="email" v-model="inputs.email" />
                        </FormField>
                        <FormField field-name="password" label="Password" :errors="regState.errors.password1">
                            <Password v-model="inputs.password1" toggleMask />
                        </FormField>
                        <FormField field-name="password2" label="Confirm" :errors="regState.errors.password2">
                            <Password v-model="inputs.password2" toggleMask :feedback="false" />
                        </FormField>
                    </form>
                    <Button @click="createAccount(inputs)" label="Create an account" class="p-button-success" />
                    <div>
                        You have already an account?
                    </div>
                    <span class="p-buttonset">
                        <router-link :to="{ name: 'login'}">
                            <Button icon="fa-duotone fa-sign-in" label="Login" />
                        </router-link>
                        <router-link :to="{ name: 'password_reset'}">
                            <Button icon="fa-duotone fa-undo" label="Reset password" class="p-button-secondary" />
                        </router-link>
                    </span>
                </template>
                <template v-else>
                    <div>
                        Registration complete. You should receive an email shortly with instructions on how to
                        activate your account.
                    </div>
                    <div>
                        <router-link :to="{ name: 'login'}">return to login page</router-link>
                    </div>
                </template>
            </template>
        </Card>
    </div>
</template>

<script>
import { reactive } from 'vue'
import Auth         from "@/store/auth"
import Card         from "primevue/card"
import InputText    from "primevue/inputtext"
import Password     from "primevue/password"
import Button       from "primevue/button"
import FormField    from "@/components/FormField"
import ErrorList    from "@/components/ErrorList.vue"

//import { useRouter  } from 'vue-router'

export default {
    setup () {
        const inputs = reactive ({
        username: '',
        password1: '',
        password2: '',
        email: '',
        })
        const createAccount = async () => {
            await Auth.createAccount (inputs)
        }
        return {inputs, regState: Auth.getRegState (), createAccount}
    },
    components: {Card, InputText, Password, Button, ErrorList, FormField}
}
</script>

<style>
</style>
