<template>
    <div>Dashboard</div>
    <p>{{ astate.user.groups }}</p>
</template>

<script>
import auth         from "@/store/auth"
export default {
    name: "DashBoard",
    setup () {
        return {astate: auth.getAuthState ()}
    }
}
</script>

<style>

</style>