<template>
    <div class="p-grid p-justify-center p-align-center full-height">
        <div class="p-col-3">
        <Card>
            <template #title>
                Specify the new password
            </template>
            <template #content>
                <template v-if="regState.resetState == 'processing'">loading...</template>
                <template v-else-if="regState.resetState == 'input'">
                    <form @submit.prevent="submit" class="p-fluid">
                        <ErrorList title="A error occured while processing your request." :errors="regState.errors.non_field_errors"/>
                        <FormField field-name="new_password1" label="Password" :errors="regState.errors.new_password1">
                            <Password v-model="inputs.new_password1" toggleMask />
                        </FormField>
                        <FormField field-name="new_password1" label="Confirm" :errors="regState.errors.new_password2">
                            <Password v-model="inputs.new_password2"  toggleMask :feedback="false"/>
                        </FormField>
                    </form>
                    <Button @click="resetPassword" icon="fa-duotone fa-undo" label="Reset Password" />
                </template>
                <template v-else>
                    <div>
                        Your password has been reset.
                    </div>
                    <router-link :to="{name: 'login'}">return to login page</router-link>
                </template>
            </template>
        </Card>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import { useRoute } from 'vue-router'
import Auth         from "@/store/auth"
import Button       from "primevue/button"
import Card         from "primevue/card"
import Password     from "primevue/password"
import FormField    from "@/components/FormField"
import ErrorList    from "@/components/ErrorList.vue"

export default {
    setup () {
        const route = useRoute ()
        const inputs = reactive ({
            new_password1: '',
            new_password2: '',
            uid:   route.params.uid,
            token: route.params.token,
        })
        const resetPassword = async () => {
            await Auth.resetPasswordChange (inputs)
        }
        return {inputs, regState: Auth.getRegState (), resetPassword}
    },
    components: {Card, Button, Password, ErrorList, FormField},
}

</script>

