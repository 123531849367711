<template>
    <Dropdown
        :options="options" optionLabel="name" optionValue="value"
        :modelValue="modelValue"
        @change="changeValue"
        />
</template>

<script>
import Dropdown                     from 'primevue/dropdown'

export default {
    emits: ["update:modelValue"],
    props: {
        modelValue: {type: Number, require: true},
        options: {type: Array, require: true},
    },
    setup (props, {emit}) {
        const changeValue = (event) => {
            emit ("update:modelValue", event.value)
        }
        return {changeValue}
    },
    components: {Dropdown}
}
</script>

<style>

</style>