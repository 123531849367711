import {reactive}   from "vue"
import auth         from "../api/auth"
import session      from "../api/session"

const TOKEN_STORAGE_KEY = "AUTH_TOKEN"
const USER_STORAGE_KEY  = "USER_TOKEN"
//const isProduction      = process.env.NODE_ENV === "production"

let authState = reactive ({
    token :    localStorage.getItem (TOKEN_STORAGE_KEY),
    userId:    0,
    user:     {
        username:  "",
        email:     "",
        firstName: "",
        lastName:  "",
        groups:    {}
    },
    errors:     {},
})

const loginUser = JSON.parse (localStorage.getItem (USER_STORAGE_KEY))
if (loginUser) {
    authState.user = loginUser
}

let regState = reactive ({
    registrationState: "input",
    resetState:        "input",
    createState:       "input",
    errors:            {}
})

const isLoggedIn = () => { return !!authState.token}
const token      = () => { return authState.token}
const login = async ({username, password}) => {
    return await auth.login(username, password)
        .then  (({ data }) => {
            authState.error          = {}
            authState.token          = data.key
            authState.user.email     = data.user.email
            authState.user.username  = data.user.username
            authState.user.firstName = data.user.first_name
            authState.user.lastName  = data.user.last_name
            authState.user.groups    = {}
            for (const g of data.user.groups) {
                authState.user.groups [g.name] = true
            }
            localStorage.setItem (TOKEN_STORAGE_KEY, data.key)
            localStorage.setItem (USER_STORAGE_KEY,  JSON.stringify(authState.user))
            session.defaults.headers.Authorization  = `Token ${authState.token}`
        })
        .catch ((error) => {
            if (error.response)
                authState.errors = error.response.data
                authState.token  = null
            })
    }
const logout = async () => {
    return await auth.logout ()
        .then  (() => {
            authState.token  = null
            authState.errors = {}
            authState.user   = {groups: {}}
            localStorage.removeItem (TOKEN_STORAGE_KEY)
            localStorage.removeItem (USER_STORAGE_KEY)
            delete session.defaults.headers.Authorization
        })
        .catch ((error) => {
            if (error.response)
                authState.errors = error.response.data
                authState.token  = null
            })
    }
const getAuthState = () => {return authState }

const getRegState  = () => {return regState  }
const resetPassword = async ({email}) => {
    regState.resetState = "processing"
    regState.errors     = {}
    return await auth.sendAccountPasswordResetEmail(email)
        .then  (() => {
            regState.resetState = "done"
            console.log (regState.resetState)
        })
        .catch ((error) => {
            regState.resetState = "input"
            if (error.response)
                regState.errors = error.response.data
        })
    }
const resetPasswordChange = async ({new_password1, new_password2, uid, token}) => {
    regState.resetState = "processing"
    regState.errors     = {}
    return await auth.resetAccountPassword (uid, token, new_password1, new_password2)
        .then  (() => {
            regState.resetState = "done"
        })
        .catch ((error) => {
            regState.resetState = "input"
            if (error.response)
                regState.errors = error.response.data
        })
}
const createAccount = async ({username, password1, password2, email}) => {
    regState.errors      = {}
    regState.createState = "processing"
    return await auth.createAccount (username, password1, password2, email)
        .then  (() => {
            regState.createState = "done"
        })
        .catch ((error) => {
            regState.createState = "input"
            if (error.response)
                regState.errors = error.response.data
        })
}
const activateAccount = async ({key}) => {
    regState.errors      = {}
    return await auth.verifyAccountEmail(key)
        .catch ((error) => {
            if (error.response)
                regState.errors = error.response.data
        })
}
const changePassword = async ({new_password1, new_password2}) => {
    regState.resetState = "processing"
    regState.errors     = {}
    return await auth.changeAccountPassword (new_password1, new_password2)
        .then  (() => {
            regState.resetState = "done"
        })
        .catch ((error) => {
            regState.resetState = "input"
            if (error.response)
                regState.errors = error.response.data
        })
}

export default {
    isLoggedIn, login, token, logout, getAuthState,

    getRegState, resetPassword, resetPasswordChange, createAccount, activateAccount, changePassword
}