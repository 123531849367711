<template>
    <Card>
        <template #title>{{ model.verbose_name_p }}</template>
        <template #content>
            <ObjectList
                :objects="state.objects"
                :listView="model [view]"
                :model="model"
                :loading="state.loading"
                @addObject="state.objects.push(event)"
                showButtons
            />
            <Toast />
        </template>
    </Card>
</template>

<script>
import { reactive, readonly, onBeforeMount, watch }         from 'vue'
import models               from '@/store/models'
import Card                 from 'primevue/card'
import Toast                from 'primevue/toast'
import ObjectList           from "@/components/models/ObjectList"

export default {
    name: "ListView",
    components: {ObjectList, Toast, Card},
    props: {
        "view"       : {type: String, defaut:   "listView"},
        "model"      : {type: Object, required: true}
    },
    setup (props) {
        const state = reactive ({ objects: [], message: null, loading: true})
        const loadObjects = async () => {
            state.loading = true
            const {error, objects} = await models.loadObjects (props.model.name)
            if (objects) state.objects = objects
            if (error) {
                state.message = {kind: "error", message: error}
            }
            state.loading = false
        }
        watch         (props.model, loadObjects)
        onBeforeMount (loadObjects)
        return {state : readonly (state)}
    }
}
</script>

<style>
    tr th:first-child {
        width: 160px;
    }
</style>
