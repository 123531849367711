<template>
    <span>
        <span v-if="col.type == 'checkbox'">
            <InputSwitch disabled v-model="value" />
        </span>
        <span v-else>{{ value }}</span>
        <ul v-if="fieldErrors.length > 0">
            <li v-for="(e, idx) in fieldErrors" :key="idx">
                {{ e }}
            </li>
        </ul>
    </span>
</template>

<script>
import { unref }        from 'vue'
import InputSwitch      from 'primevue/inputswitch'
export default {
    components: {InputSwitch},
    props: {
        col:    {type: Object, required: true},
        index:  {type: Number, required: true},
        data:   {type: Object, required: true},
        errors: {type: Array, default() { return []}},
    },
    setup (props) {
        const col = unref (props.col)
        let value = unref (props.data [col.field])
        if (col.type == "foreign-key") {
            value = (value || [null, ""]) [1]
        } else if (col.type == "select") {
            value = col.value2Name [value]
        }
        let fieldErrors = {}
        if (props.index < props.errors.length) {
            fieldErrors = unref (props.errors [props.index])
        }
        fieldErrors = fieldErrors [props.col.field] || []
        return {value, fieldErrors}
    }
}
</script>
<style scoped>
    ul {
        list-style: none;
        padding:    0px;
        margin:     0px;
        color:    red;
        margin-bottom: 0.5em;
    }

</style>