<template>
	<div class="layout-menu-container">
		<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
	</div>
</template>

<script>
import AppSubmenu from "@/components/main/AppSubmenu"
import {useRouter} from "vue-router"

export default {
	props: {
		model: Array
	},
	setup (props, context) {
		const router = useRouter ()
        const onMenuItemClick = (event) => {
			if (event.item.route) {
				router.push ({name: event.item.route, params: event.item.params})
			} else if (event.item.model) {
				router.push ({name: "module-definition", params: {modelName: event.item.model}})
			}
            context.emit('menuitem-click', event);

        }
		return {onMenuItemClick}
    },
	components: {AppSubmenu}
}
</script>

<style scoped>

</style>