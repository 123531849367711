import session      from './session'
import {reactive}        from "vue"

const translationState = reactive ({languages: {}})

const translation = {
    async loadLanguages (email) {
        const r = await session.get (`translation/load-languages/`, {params : {email}})
        return r.data
    },
    async loadTranslations (source, target) {
        const r = await session.get (`translation/load-translations/`, {params : {source, target}})
        return r.data
    },
    async saveTranslations (translations, uid_trs, status_codes, features, data_center, target) {
        const data = {translations, uid_trs, status_codes, features, data_center}
        const r = await session.post (`translation/save-translations/${target}/`, data)
        return r.data
    },
    async downloadTranslations (downloads) {
        return await session.request ({ url: `translation/download/` ,
                           method: "GET",
                           responseType: 'blob',
                        params: {downloads}}).then((resp) => {
            const downloadUrl = window.URL.createObjectURL (new Blob ([resp.data]))
            const link        = document.createElement ('a')
            link.href         = downloadUrl
            link.setAttribute         ('download', resp.headers ["content-disposition"].split ("=") [1])
            document.body.appendChild (link)
            link.click                ()
            link.remove               ()
        })
    },
}

export default translation
export {translation, translationState}