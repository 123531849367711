<script>
import {h, computed, watch}        from "vue"
import AutoComplete         from 'primevue/autocomplete'    // eslint-disable-line no-unused-vars
import Checkbox             from 'primevue/checkbox'        // eslint-disable-line no-unused-vars
import Dropdown             from 'primevue/dropdown'        // eslint-disable-line no-unused-vars
import Fieldset             from 'primevue/fieldset'        // eslint-disable-line no-unused-vars
import InputNumber          from 'primevue/inputnumber'     // eslint-disable-line no-unused-vars
import InputSwitch          from 'primevue/inputswitch'     // eslint-disable-line no-unused-vars
import InputText            from 'primevue/inputtext'       // eslint-disable-line no-unused-vars
import Listbox              from 'primevue/listbox'         // eslint-disable-line no-unused-vars
import MultiSelect          from 'primevue/multiselect'     // eslint-disable-line no-unused-vars
import Panel                from 'primevue/panel'           // eslint-disable-line no-unused-vars
import RadioButton          from 'primevue/radiobutton'     // eslint-disable-line no-unused-vars
import SelectButton         from 'primevue/selectbutton'    // eslint-disable-line no-unused-vars
import Textarea             from 'primevue/textarea'        // eslint-disable-line no-unused-vars
import ToggleButton         from 'primevue/togglebutton'    // eslint-disable-line no-unused-vars
import TriStateCheckbox     from 'primevue/tristatecheckbox'// eslint-disable-line no-unused-vars
import ForeignKeyInput      from '@/components/models/ForeignKeyInput'  // eslint-disable-line no-unused-vars
import InlineField          from '@/components/models/InlineField'      // eslint-disable-line no-unused-vars

import api                  from "@/api/models"

var builders = {
    group : function (CE, spec, obj, fields, data) {
        var pdata
        var result
        var wrapChild      = data.wrapChild
        if (spec.orientation != "horizontal") {
            data.wrapChild = _wrapVertical
        } else {
            data.wrapChild = _wrapHorizontal
        }
        var children       = _createVNodes (CE, spec.children, obj, fields, data)
        data.wrapChild     = wrapChild
        if (! wrapChild) { // top level needs special handling
            var edata = {class: "p-fluid p-formgrid p-grid"}
            if (spec.readOnly) edata ["class"] += " p-inputtext-sm"
            result = CE ("div", edata, children)
            if (spec.title) {
                pdata  = {
                    header: spec.title, toggleable: spec.toggleable || false}
                result = CE (Panel, pdata, [result])
            }
        } else {
            if (spec.orientation == "horizontal") {
                let cls = "p-grid"
                if (spec.readOnly) cls += " p-inputtext-sm"
                result = [CE ("div", {class: cls}, children)]
            } else
                result = children
            if (spec.title) {
                pdata  = {header: spec.title, toggleable: spec.toggleable}
                result = CE (Panel, pdata, result)
            }
        }
        return result
    },
    field: function (CE, spec, obj, fields, data) {
        var field = fields [spec.field]
        var fieldFct   = fieldTypes [spec.fieldType || field.type]
        if (!field || ! fieldFct) {
            console.warn (
                "Ignore field", spec.field,
                spec.fieldType || field.type, field, fieldFct)
            return
        }
        if (spec.visible) {
            const visible = eval (spec.visible)
            if (! visible) {
                // console.log (`Hide Elemen ${field.field} due to ${spec.visible}`)
                return
            }
        }
        var label
        var {wtag, wdata, options} = fieldFct (field, spec, obj, data)
        wdata ["id"] = field.field
        if (! wdata.modelValue) {
            if (field.computed && field.computed.get) {
                const compProp = computed (() => {
                    return field.computed.get (undefined, obj)})
                wdata.modelValue  = compProp.value
            }
            else wdata.modelValue = obj [field.field]
        }
        if (data.initial [field.field] === undefined) {
            data.initial [field.field] = wdata.modelValue
        }
        var event = ((options || {}).event) || "onInput"
        if (! wdata [event]) {
            wdata [event]    = function (evt) {
                let value = evt.value
                if      (evt.originalTarget) value = evt.originalTarget.value
                else if (evt.currentTarget)  value = evt.currentTarget.value
                if (event == "onUpdate:modelValue") value = evt
                if (field.computed && field.computed.set)
                    value = field.computed.set (value, obj)
                else obj [field.field] = value
                var changed = value != data.initial [field.field]
                console.log ("V-vhanged", changed, spec)
                if (changed) {
                    if (data.changes [field.field] != value) {
                        data.changes [field.field] = value
                    } else {
                        if (data.changes [field.field] !== undefined) {
                            delete data.changes [field.field]
                        }
                        if (field.validations) {
                            delete data.errors [field.field]
                        }
                    }
                }
                if (field.validations && (changed || obj [data.form.pk] < 1)) {
                    const fdata = data
                    api.validateField (
                        data.self.spec.name,
                        obj [data.form.pk],
                        field.field,
                        value,
                        field.validations
                    ).then (({data}) => {
                        if (data.status) {
                            delete fdata.errors [field.field]
                        }
                        else {
                            fdata.errors [field.field] = data.messages
                        }
                    })
                }
            }
        }
        var error = null
        if (data.errors [field.field]) {
            var messages = data.errors [field.field].map (v => {
                return CE ("li", v)
            })
            error = CE ("ul", { class: "p-invalid"}, messages)
            if (!wdata ["class"]) wdata ["class"]  = "p-invalid"
            else                  wdata ["class"] += " p-invalid"
        }
        if (spec.readOnly) {
            //if (!wdata.attrs) wdata.attrs = {}
            wdata ["disabled"] = true
        }
        var widget = CE (wtag, wdata)
        if (field.label)
            label = CE ("label", {domAttrs : {"for": field.field}}, field.label)
        var children
        if (label && data.form.floatingLables) {
            children = [widget, label]
            if (error) children.push (error)
            return CE (
                "span", {class: "p-float-label"}
                , [widget, label]
                )
        } else {
            children = []
            if (label) children.push (label)
            children.push (widget)
            if (error) children.push (error)
            return CE (
                "div", { class : "p-field p-col-12"}
                , children
                )
        }
    },
    static: function (CE, spec, obj, fields, data) { // eslint-disable-line no-unused-vars
        var tag   = spec.tag || "div"
        var edata = {}
        if (spec.html) {
            edata.domProps = {innerHtml: spec.html}
        }
        return CE (tag, edata, spec.text || [])
    },
    inline: function (CE, spec, obj, fields, data) {
        var tag        = InlineField
        var field      = fields [spec.field]
        if (field === undefined)
            console.error ("Nor field found for", spec.field)
        var model      = Object.assign ({}, field.model)
        model.listView = {
            fields: spec.listView,
            filter: spec.filter || {},
            sortable: spec.sortable || []
        }
        if (data.initial [field.field] === undefined) {
            data.initial [field.field] = [...obj [spec.field]]
        }
        const objects = obj [spec.field]
        var edata = { model: model,
            formName: field.formName,
            listView: model.listView,
            header: field.model.verbose_name_p,
            objects: objects,
            reorderSpec: spec.reorderSpec,
            errors: data.errors [spec.field] || [],
            toolbar:spec.toolbar,
            inlineListComponent:spec.inlineListComponent,
            onReorderRow: (event) => {
                const item = objects.splice (event.dragIndex, 1) [0]
                objects.splice (event.dropIndex, 0, item)
            },
            onRemoveRow: (idx) => {
                obj [spec.field].splice (idx, 1)
            },
            onAddRow: (data) => {
                data = data || {}
                const source = data.source
                const object = data.object
                object [model.form.pk] = -objects.length
                if (source) {
                    const idx = objects.indexOf (source)
                    if (idx >= 0) {
                        objects.splice (idx + 1, 0, object)
                    } else {
                        objects.push (object)
                    }
                } else {
                    objects.push (object)
                }
            },
            onChangeRow:({index, newData}) => {
                objects [index] = newData
            }
        }
        return CE (tag, edata)
    },
    hidden: function (CE, spec, obj, fields, data) {  // eslint-disable-line no-unused-vars
        var tag   = spec.tag || "div"
        var edata = {}
        if (spec.html) {
            edata.domProps = {innerHtml: spec.html}
        }
        return CE (tag, edata, spec.text || [])
    }
}

var _createVNodes = function (CE, children, obj, fields, data) {
    var result = []
    for (var i = 0; i < (children || []).length; i++) {
        var spec  = children [i]
        var bf    = builders [spec.type]
        if (bf !== undefined) {
            var child = bf (CE, spec, obj, fields, data)
            if (data.wrapChild) {
                if (!Array.isArray (child))
                    child = [child]
                child = data.wrapChild (CE, spec, child)
            } else if (Array.isArray (child)) {
                child = CE ("span", child)
            }
            result.push (child)
        } else {
            console.error ("No builder function for", spec.type)
        }
    }
    return result

}
var fieldTypes = {
    "integer" :     function (field, spec, obj, data) { // eslint-disable-line no-unused-vars
        return {
            wtag: InputNumber,
            wdata : {
                showButtons: true,
                //mode: "decimal",
                useGrouping: false,
                min: field.minValue,
                max: field.maxValue,
            }
        }
    },
    "text":         function (field, spec, obj, data) { // eslint-disable-line no-unused-vars
        return {
            wtag: InputText,
            wdata: {
            }
        }
    },
    "foreign-key" : function (field, spec, obj, fbdata) { // eslint-disable-line no-unused-vars
        if (fbdata.self.suggestions [field.field] === undefined) {
            fbdata.self.suggestions [field.field] = []
        }
        return {
            wtag: ForeignKeyInput,
            wdata: {field, object : obj}
        }
    },
    "checkbox":     function (field, spec, obj, data) { // eslint-disable-line no-unused-vars
        return {
            wtag: ToggleButton,
            wdata: {
                onIcon: "fa-regular fa-times-square",   //  onLabel:  " ",
                offIcon: "fa-regular fa-square", // offLabel: " "
            },
            options: {event: "onUpdate:modelValue"}
        }
    },
    "textarea":     function (field, spec, obj, data) { // eslint-disable-line no-unused-vars
        let attrs = {}
        if (spec.rows) attrs.rows = spec.rows
        return {
            wtag: Textarea,
            wdata: { attrs: attrs}
        }
    },
    "integer-hex":  function (field, spec, obj, data) { // eslint-disable-line no-unused-vars
        return {wtag: InputText, wdata: {}}
    },
    "bit-field" : function (field, spec, obj, fbdata) { // eslint-disable-line no-unused-vars
        return {
            wtag: MultiSelect,
            wdata: { options: field.choices,
                     optionLabel: "label",
                     optionValue: "value"
                   },
            options: {event: "onUpdate:modelValue"}
        }
    },
    "select":       function (field, spec, obj, fbdata) { // eslint-disable-line no-unused-vars
        return {
            wtag: Dropdown,
            wdata: { options: field.choices,
                     optionLabel: "label",
                     optionValue: "value"
                   },
            options: {event: "onUpdate:modelValue"}
        }
    }
}

function _wrapVertical (CE, spec, children) { // eslint-disable-line no-unused-vars
    return CE ("div", {class: "p-col-12"}, children)
}

function _wrapHorizontal (CE, spec, children) {
    var cls = "p-col"
    if (spec.columns) cls += "-" + spec.columns
    if (spec.offset)  cls += " p-col-offset-" + spec.columns
    return CE ("div", {class: cls}, children)
}

export default {
    name: "FormBuilder",
    emits: ["update:hasErrors", "updateHasChanges"],
    props: {
        form:       {type: Object, required: true},
        spec:       {type: Object, required: true},
        object:     {type: Object, required: false, default() {return {}}},
        initial:    {type: Object, required: false, default() {return {}}},
        errors:     {type: Object, required: false, default() {return {}}},
        hasErrors:  {type: Boolean, default: false}
    },
    data () {
        return {changes: {}, suggestions: {},}
    },
    render () {
        const initial = this.initial
        const changes = this.changes
        const children = _createVNodes (
            h, this.form.children, this.object, this.spec.fields, {
                self:           this,
                form:           this.form,
                initial:        initial,
                changes:        changes,
                errors:         this.errors})
        let attrs = {id:"RootOfTheStack"}
        watch (() => changes, () => {
            var result = false
            for (var k in this.changes) // eslint-disable-line no-unused-vars
                { result = true; break}
            this.$emit ("updateHasChanges", result)
        }, {deep: true})
        watch (() => this.errors, () => {
            const result = Object.keys (this.errors).length > 0
            this.$emit ("update:hasErrors", result)
        }, {deep: true})
        if (this.form.fuildLayout == true)
            attrs ["class"] = "p-fluid"
        return h ("div", attrs, children)
    }
}
</script>

<style>
    .p-col-12 .p-button.p-togglebutton.p-component.p-button-icon-only {
        width: inherit;
    }
</style>