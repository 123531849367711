<template>
    <span>
        {{ modelValue.length }} <Button icon="fa-regular fa-plus" class="p-button-sm p-button-warning" label="Add" style="margin-left: 2em" @click="$emit ('add-choice')" />
    </span>
</template>

<script>
export default {
    props: {
        modelValue: {type: [Array, Number], required: true}
    },
    emits: ["add-choice"]
}
</script>

<style>

</style>