<template>
    <Card>
        <template #title>
            Upload new translation source files
        </template>
        <template #content>
            <div v-if="groups.TRANSLATE_UPLOAD_LANG">
                <label>Which languages should be updated</label>
                <InputText v-model="state.languages" autofocus />
                <input type="hidden" name="langages" :value="state.languages" />
            </div>
            <FileUpload name="files[]" 
                        :url="postUrl" 
                        :maxFileSize="1000000" 
                        @upload="done" 
                        :multiple="true"
                        >
                <template #empty>
                    <p>Drag and drop files to here to upload.</p>
                </template>                        
            </FileUpload>
        </template>
    </Card>
</template>

<script>
//import { useRouter }            from 'vue-router'
import { reactive, computed }   from 'vue'
import { useToast }             from 'primevue/usetoast'
import Card                     from 'primevue/card'
import FileUpload               from 'primevue/fileupload'
import auth                     from "@/store/auth"
import InputText                from 'primevue/inputtext'

export default {
    components: {Card, FileUpload, InputText},
    setup () {
		const toast = useToast()
        const groups = auth.getAuthState().user.groups
        const state = reactive ({languages: "de"})
        if (!groups.TRANSLATE_UPLOAD_LANG) {
            state.languages = ""
        }
        const done = (event) => {
            let resp = JSON.parse (event.xhr.responseText)
            toast.add({severity: 'info', 
                summary: 'Success', 
                detail: `Keep ${resp.keep}, created ${resp.created}, deleted ${resp.deleted}` , life: 3000})
            console.log (resp)
        }
        const postUrl = computed (() => {
            return '/api/v2/translation/upload/?languages=' + state.languages
        })
        return {state, done, groups, postUrl}
    }
}
</script>

<style>

</style>