<template>
    <ProgressSpinner />
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
    componets: {ProgressSpinner}
}
</script>

<style>

</style>