import axios from 'axios'

const CSRF_COOKIE_NAME = 'csrftoken'
const CSRF_HEADER_NAME = 'X-CSRFToken'

const session = axios.create({
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
  baseURL:        '/api/v2/',
})

session.triggerFileDownload = async (url, params) => {
    return await session.request ({ url: url ,
        method:         "GET",
        responseType:   'blob',
        params: {params}}).then ((resp) => {
            const downloadUrl = window.URL.createObjectURL (new Blob ([resp.data]))
            const link        = document.createElement ('a')
            link.href         = downloadUrl
            link.setAttribute         ('download', resp.headers ["content-disposition"].split ("=") [1])
            document.body.appendChild (link)
            link.click                ()
            link.remove               ()
        })
    }

export default session
