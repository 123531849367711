<template>
    <ParameterTable
        :secLevel="secLevel"
        :spec="spec"
        :values="values"
        :loading="loading"
        :secLevels="secLevels"
        :projectId="projectId"
        @update:value="changeSubValue($event, spec, -100)"
        @add-instance="addInstance ($event)"
    />
    
</template>

<script>
export default {
    props: {
        secLevel:   {tyoe: Number, default: 0x01},
        spec:       {type: Object,  required: true},
        values:     {type: [Array, Object],  required: true},
        loading:    {type: Boolean, default: true},
        secLevels:  {type: Object,  required: true},
        projectId:  {type: Number,  required: true},
    },
    emits: ["update:value", "add-instance"],
    setup (props, {emit}) {
        const changeSubValue = (evt, data, idx) => {
            emit ("update:value", data, idx)
        }
        const addInstance = ({pk, instance}) => {
            emit ("add-instance", {pk, instance})
        }
        return {changeSubValue, addInstance}
    }
}
</script>

<style>

</style>