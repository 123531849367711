<template>
    <div>
        <DataTable ref="objectList" class="p-datatable-striped p-datatable-sm"
            :value="objects"
            dataKey="id"
            autoLayout
            :loading="loading"

            paginator
            paginatorPosition="both"
            :rowsPerPageOptions="[10,25,50,100]"
            :rows="25"

            :filters="state.filters"
            :filterDisplay="listView.filterDisplay"

            rowHover
            v-model:selection="state.selectedObject"
            selectionMode="single"
            :sortField="sortOrder.field"
            :sortOrder="sortOrder.order"
        >
            <Column v-for="col of columns"
                :field="col.field"
                filterField="name"
                :header="col.label"
                :key="col.field"
                :sortable="listView.sortable.indexOf (col.field) >= 0"
                >
                <template #body="props">
                    <template v-if="col.type =='foreign-key'">
                        {{ (props.data [col.field] || [null, ""]) [1]}}
                    </template>
                    <template v-else-if="col.type =='select'">
                        {{ col.value2Name [props.data [col.field]] }}
                    </template>
                    <template v-else-if="col.type =='checkbox'">
                        <InputSwitch v-model="props.data [col.field]" disabled />
                    </template>
                    <template v-else>
                        {{ props.data [col.field] }}
                    </template>
                </template>
                <template #filter v-if="listView.filter[col.field]">
                    <InputText type="text" v-model="state.filters[col.field].value" class="p-column-filter" />
                </template>
            </Column>
            <Column :exportable="false" headerStyle="width: 120px">
                <template #body="slotProps">
                    <Button icon="fa-solid fa-clone"   class="p-button-rounded p-button-text p-button-warning"     @click="editObject ({object: slotProps.data, asNew: true})"
                        v-if="modelCopy"
                    />
                    <Button icon="fa-solid fa-trash"  class="p-button-rounded p-button-text p-button-danger"      @click="askDeleteConfirm({object:slotProps.data})"
                        v-if="modelDelete"
                    />
                    <Button icon="fa-solid fa-pencil" class="p-button-rounded p-button-text p-button-info p-mr-1" @click="editObject ({object: slotProps.data, asNew: false})"
                    />
                </template>
            </Column>
            <template #header v-if="showButtons && modelCopy">
                <Toolbar class="p-mb-4">
                    <template #end>
                        <Button label="New"    icon="fas fa-plus" class="p-button-success p-mr-2" @click="addNew" />
                    </template>
                </Toolbar>
            </template>
            <template #footer v-if="showButtons && modelCopy">
                <Toolbar class="p-mb-4">
                    <template #end>
                        <Button label="New"    icon="fas fa-plus" class="p-button-success p-mr-2" @click="addNew" />
                    </template>
                </Toolbar>
            </template>
        </DataTable>
        <ConfirmDialog />
        <Dialog v-model:visible="state.editDialogVisible"
                @hide="callRevertFunction"
                modal
        >
            <template #header>
                {{ model.verbose_name }}
            </template>
            <ObjectEdit :modelName="model.name"
                        :formName="formName"
                        :id="state.objectToEdit"
                        :asNew="state.editAsNew"
                        toolbar="bottom"
                        :useRouter="false"
                        @editFinished="state.editDialogVisible = false"
                        @revertFunction="setRevertFunction"
                        />
        </Dialog>
    </div>
</template>

<script>
import { reactive, computed, unref } from 'vue'
import { useRouter  }                from 'vue-router'
import auth                          from "@/store/auth"
import models                        from '@/store/models'
import ObjectEdit           from '@/components/models/ObjectEdit'
import Dialog               from 'primevue/dialog'
import DataTable            from 'primevue/datatable'
import Column               from 'primevue/column'
import InputText            from 'primevue/inputtext'
import InputSwitch          from 'primevue/inputswitch'
import Toolbar              from 'primevue/toolbar';
import ConfirmDialog        from 'primevue/confirmdialog';
import { useConfirm }       from "primevue/useconfirm";

export default {
    components: {DataTable, Column, InputText, InputSwitch, ConfirmDialog, Toolbar, Dialog, ObjectEdit},
    emits: ["error-message", "add-object"],
    props: {
        objects:      {type: Array,  required: true},
        listView:     {type: Object, required: true},
        model:        {type: Object, required: true},
        reorderSpec:  {type: Object, default() { return {enabled: false}}},
        formName:     {type: String, default: "default"},
        showButtons:  {type: Boolean, default: false},
        errors:       {type: Array},
        loading:      {type: Boolean, defaukt: false},
        inlineEdit:   {type: Boolean, defaukt: false}
    },
    setup (props, {emit}) {
        const router  = useRouter  ()
        const confirm = useConfirm ()
        const state   = reactive ({
            filters: {},
            selectedObject: null,
            objectToEdit: {},
            editDialogVisible: false,
            editAsNew:         false,
        })
        const aState = auth.getAuthState ()
        const modelDelete = computed (() => aState.user.groups.MODEL_EDIT == true)
        const modelCopy   = computed (() => aState.user.groups.MODEL_EDIT == true)
        for (const [key, value] of Object.entries (props.listView.filter)) {
            state.filters [key] = {value: "", matchMode: value}
        }
        const columns = computed (() => {
            const result = []
            if (props.model) {
                props.listView.fields.forEach ((name) => {
                    const field = Object.assign ({}, props.model.fields [name])
                    result.push (field)
                })
            }
            return result
        })
        const editObject = ({object, asNew}) => {
            const pkField = props.model.form.pk
            const form = props.model.form [props.formName]
            if (!form.popup) {
                router.push ({
                    name: "object-edit",
                    params: {modelName: props.model.name, id: object [pkField]},
                    query: {formName: props.formName, asNew: asNew}
                    })
            } else {
                state.objectToEdit      = object [pkField].toString ()
                state.editAsNew         = asNew.toString ()
                state.editDialogVisible = true
            }
        }
        const addNew = async () => {
            const pkField = props.model.form.pk
            const minPk   = Math.min (0,
                ...props.objects.map ((o) => o [pkField]))
            const object = {}
            object [pkField] = minPk - 1
            editObject ({object, asNew: true})
        }
        const askDeleteConfirm = ({object}) => {
            confirm.require({
                message: 'Are you sure you want to delete this object?',
                header: 'Confirmation',
                icon: 'fa-duotone fa-exclamation-triangle',
                acceptIcon: "fa-solid fa-trash",
                acceptLabel: "Yes",
                rejectIcon: "fa-solid fa-times",
                accept: async () => {
                    const result = await models.deleteObject (object, props.model.name)
                    if (result !== true) {
                        emit ("error-message", result)
                    }
                },
            });
        }
        const sortOrder = {field: null, order: 1}
        if (props.listView.defaultSort) {
            sortOrder.field =  unref (props.listView.defaultSort)
            if (sortOrder.field [0] == "-") {
                sortOrder.field = sortOrder.field.substring (1)
                sortOrder.order = -1
            }
        }
        let _revertFunction = null
        const setRevertFunction = (fct) => {
            console.log (fct)
            _revertFunction = fct
        }
        const callRevertFunction = () => {
            _revertFunction ()
        }
        return { state, columns, addNew, editObject
               , askDeleteConfirm, sortOrder
               , setRevertFunction, callRevertFunction

               , modelDelete, modelCopy
               }
    }
}
/*
        addNew() {
             api.defaults (this.model.name).then (({data}) => {
                 if (data.status) {
                     this.editObject ({object: data.object, asNew: true})
                 } else {
                    this.$toast.add ({
                        severity: "error",
                        summary: "Create of new object failed",
                        detail: data.message,
                        life: 3000})
                 }
             }).catch (data => {
                this.$toast.add ({
                    severity: "error",
                    summary: "Create of new object failed",
                    detail: data,
                    life: 3000})
             })
        },
        editObject ({object, asNew}) {
            if (this.editInExpand) {
                const idx = this.expandedRows.indexOf (object)
                if (idx >= 0) {
                    this.expandedRows.splice (idx, 1)
                } else {
                    if (asNew) {
                        const minPk = Math.min (0,
                            ...this.objects.map ((o) => o [this.model.form.pk]))
                        object [this.model.form.pk] = minPk - 1
                        this.objects.push (object)
                        this.firstRow = this.objects.length - 1
                    }
                    this.expandedRows.push (object)
                }
            } else {
                this.$emit ("edit-object", {object, asNew})
            }
        },
        errorsForObject(obj) {
            const idx = this.objects.indexOf (obj)
            return this.errors [idx]
        },
        reorderRow (event) {
            let item = this.objects.splice (event.dragIndex, 1) [0]
            let dstIdx = event.dropIndex
            this.objects.splice (dstIdx, 0, item)
        },
        askDeleteConfirm(obj) {
            this.objectToDelete = obj
            this.deleteDialogVisible = true
        },
        deleteObject() {
            const pk = this.objectToDelete [this.model.form.pk]
            if (pk < 0) {
                this.$store.commit ("models/DELETE_INSTANCES",
                    {modelName: this.modelName, pk: pk, objects: this.objects})
                const idx = this.expandedRows.indexOf (this.objectToDelete)
                if (idx >= 0) {
                    this.expandedRows.splice (idx, 1)
                }
                this.deleteDialogVisible = false
            } else {
                this.$store.dispatch ('models/deleteObject',
                    {modelName : this.model.name, pk: pk, objects: this.objects}).then (({status, message}) => {
                        if (message)
                            this.$toast.add ({
                                severity: status ? "success" : "error",
                                summary: "Object deletion",
                                detail: message,
                                life: 3000})
                        this.deleteDialogVisible = false
                    })
            }
        }
    },
}
*/
</script>

<style>
.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.fa-rotate-45 {
    transform: rotate(45deg);
}
</style>