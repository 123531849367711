import session from './session'

export default {
    async loadModel (modelName) {
        return await session.get (`model-definition/${modelName}/`)
    },
    loadObjects (modelName) {
        return session.get (`model-objects/${modelName}/`)
    },
    deleteObject (modelName, pk) {
        return session.delete (`model-objects/${modelName}/${pk}/`)
    },
    saveObject (modelName, pk, changes) {
        return session.put (`model-objects/${modelName}/${pk}/`, changes)
    },
    newObject (modelName, changes) {
        return session.post (`model-objects/${modelName}/`, changes)
    },
    defaults (modelName, pk) {
        return session.get (`model-objects/${modelName}/${pk}/`)
    },
    autoComplete (modelName, query) {
        return session.get (`model-autocomplete/${modelName}/`, {params: query})
    },
    validateField (modelName, pk, field, value, validations) {
        return session.get (`validate/${modelName}/${pk}/${field}/`, {params: {validations, value}})
    },
    async loadProjectSettingsForModule(puh, projectSettingsId, moduleId) {
        return session.get (`project-settings-values/${puh}/${moduleId}/${projectSettingsId}/`)
    },
    async loadProjectSettings(puh, projectSettingsId) {
        return session.get (`project-settings/${puh}/${projectSettingsId}/`)
    },
    async saveProjectSettings(puh, projectSettingsId, values) {
        return session.put (`project-settings/${puh}/${projectSettingsId}/`, values)
    }        
}
