<template>
    <div class="p-field p-grid">
        <label :for="fieldName" class="p-col-fixed" :style="style">{{ label }}</label>
        <div class="p-col">
            <slot></slot>
        </div>
    </div>
    <ErrorList :errors="errors" />
</template>

<script>
import ErrorList    from "@/components/ErrorList"

export default {
    props: {
        fieldName: { type: String, required: true},
        label:     { type: String, required: true},
        errors:    { type: Array , default: () => { return []}},
    },
    setup () {
        return { style: "width: 100px"}
    },
    components: {ErrorList}
}
</script>

<style>

</style>