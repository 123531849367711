<template>
    <Card>
        <template #title>
            Project Administration
        </template>
        <template #content>
            <dl>
                <dt><Button icon="fa-duotone fa-vacuum"         label="Cleanup UID's" class="p-button-danger" @click="cleanupUIDs" :disabled="state.loading" /></dt>
                <dd>
                    <Accordion :multiple="true" v-if="!state.loading">
                        <AccordionTab :disabled="state.muid_changes.length == 0">
                            <template #header>
                                <span v-if="state.muid_changes.length">
                                    The following UID for the definition would be changed ({{state.muid_changes.length}}):
                                </span>
                                <span v-else>
                                    Now cleanups for the module defintion found!
                                </span>
                            </template>
                            <DataTable :value="state.muid_changes" :loading="state.loading"
                                :paginator="true" :rows="10"
                                stripedRows
                            >
                                <Column field="cls" header="Type" />
                                <Column field="name" header="Name" />
                                <Column field="old_uid" header="Old UID" />
                                <Column field="new_uid" header="New UID" />
                            </DataTable>
                        </AccordionTab>
                        <AccordionTab v-for="(changes, name) in state.cleaned_iuid" :key="name">
                            <template #header>
                                In project {{ name }} the following instance UID would be changed ({{ changes.length }})
                            </template>
                            <DataTable :value="changes" :loading="state.loading"
                                :paginator="true" :rows="10"
                                stripedRows
                            >
                                <Column field="cls" header="Type" />
                                <Column field="name" header="Name" />
                                <Column field="old_uid" header="Old UID" />
                                <Column field="new_uid" header="New UID" />
                            </DataTable>
                        </AccordionTab>
                    </Accordion>
                </dd>
                <dt><Button icon="fa-duotone fa-refrigerator"   label="Freeze UID's" class="p-button-warning" @click="freezeUIDs" /></dt>
                <dd>{{ state.freezeCount }} definition UID could be frozen</dd>
            </dl>
        </template>
    </Card>
</template>

<script>
import {reactive }     from "vue"
import projects             from "@/api/projects"
import Card                 from 'primevue/card'
import DataTable            from 'primevue/datatable'
import Column               from 'primevue/column'
import Accordion            from 'primevue/accordion'
import AccordionTab         from 'primevue/accordiontab'

export default {
    setup () {
        const state = reactive ({loading: true, muid_changes: [], cleaned_iuid: {}, freezeCount: 0})
        const updateStatistics = () => {
            state.loading      = true
            projects.cleanupUIDs ("1").then ((data) => {
                state.muid_changes = data.muid_changes
                state.cleaned_iuid = {}
                for (const [name, changes] of Object.entries (data.cleaned_iuid)) {
                    if (changes.length)
                        state.cleaned_iuid [name] = changes
                }
                state.loading      = false
            })
        }
        updateStatistics ()
        const updateFreezeCount = () => {
            projects.freezeUIDs ("1").then (({status, count}) => {
                if (status) {
                    state.freezeCount = count
                }})
        }
        updateFreezeCount ()
        const cleanupUIDs = async () => {
            await projects.cleanupUIDs ("0")
            updateStatistics ()
        }
        const freezeUIDs = async () => {
            const data = await projects.freezeUIDs ()
            if (data.status) {
                updateFreezeCount ()
            }
        }
        return {state, cleanupUIDs, freezeUIDs}
    },
    components: {Card, DataTable, Column, Accordion, AccordionTab}
}
</script>

<style scoped>
    dt {
        margin-top: 1em;
    }
</style>>

