<template>
  <div style="margin-top: 1em">
    <v-stage :config="konvaConfig" style="float: left">
        <v-layer>
            <v-image :config="imageConfig" />
        </v-layer>
        <v-layer>
            <v-group
                v-for="sp in spareParts"
                :key="sp.key"
                :config="{x: sp.data.x1, y: sp.data.y1, draggable: sp.draggable}"
                @dragmove="updateMarkerPos"
                @dragend="updateMarkerPos"
            >
                <v-line
                    :config="{points: [0,0, sp.data.x2, sp.data.y2, 0], stroke: 'black'}"
                />
                <v-circle 
                    :config="{radius: 20, 
                        stroke: sp.draggable ? 'red' : 'black', 
                        fill: sp.draggable ? 'lightblue' : 'lightgray'
                    }"
                />
                <v-text 
                    :config="{ text: sp.data.number, 
                                fontSize: 25, 
                                x: -10, y:-13, 
                                width: 20,
                                align: 'center',
                                fontFamily: 'Calibri'
                                }"
                />
                <v-circle v-if="state.modifyPart && (state.modifyPart.key == sp.key)"
                    :config="{x: sp.data.x2-3, y: sp.data.y2-3, radius: 6, fill: 'red', stroke: 'black', draggable: true }"
                    @dragmove="updateMarkerTargetPos"
                    @dragend="updateMarkerTargetPos"
                />
            </v-group>
        </v-layer>
    </v-stage>
    <DataTable class="editable-cells-table p-datatable-sm" 
        :value="spareParts"
        stripedRows
        v-model:selection="state.selectedParts" selectionMode="single" dataKey="data.id"
        editMode="cell" @cell-edit-complete="editCellComplete"
    >
        <Column field="data.number"  headerStyle="width:3rem">
            <template #header>
                No&nbsp;
                <Button icon="fa-duotone fa-plus" class="p-button-outlined p-button-rounded" 
                @click="addNewSparePart"
                />
            </template>
            <template #editor="{ data }">
                <InputNumber v-model="data.data.number" autofocus  class="number"/>
            </template>
        </Column>
        <Column header="Order Number" field="data.part_number" headerStyle="width:10rem">
            <template #editor="{ data }">
                <InputText v-model="data.data.part_number" autofocus />
            </template>
        </Column>
        <Column header="Description" field="data.name">
            <template #editor="{ data  }">
                <InputText v-model="data.data.name" autofocus />
            </template>
        </Column>
        <Column header="" headerStyle="width: 8rem">
            <template #body="{data}">
                <Button icon="fa-duotone fa-trash" class="p-button-outlined p-button-rounded p-button-danger" 
                    @click="confirmDelete(data)"
                    :disabled="state.modifyPart"
                /> &nbsp;
                <Button :icon="saveEditMarkerIcon(data)" class="p-button-outlined p-button-rounded" 
                    @click="startMarkerEdit(data)"
                    :disabled="state.modifyPart && (state.modifyPart.key != data.key)"
                />
            </template>
        </Column>
    </DataTable>
    </div>
</template>

<script>
import { onBeforeMount, reactive } from 'vue' // , onBeforeMount, ref
import DataTable                        from 'primevue/datatable'
import Column                           from 'primevue/column'
import InputText                        from 'primevue/inputtext'
import InputNumber                      from 'primevue/inputnumber'
import { useConfirm }                   from 'primevue/useconfirm'
import DC                               from "@/api/data_center"

export default {
    props: {
        sparePart:      { type: Array,  required: true},
        sparePartImage: { type: Number, required: true},
        image:          { type: String, required: true}
    },
    emits: ["update:sparePart"],
    components: {DataTable, Column, InputText, InputNumber},
    setup (props, {emit}) {
        const confirm = useConfirm();
        const state = reactive ({selectedParts : [], modifyPart: null})
        const konvaConfig = {width: 640, height: 330}
        const imageConfig = reactive ({ width: 640, height: 330, image: null})
        const spareParts  = reactive ([])
        for (const sp of props.sparePart) {
            spareParts.push (sp)
        }
        onBeforeMount (async () => {
            const image  = new window.Image()
            image.src    = `/api/v2/data-center/spare-part-image/${props.image}/`
            image.onload = () => {
                // set image only when it is loaded
                imageConfig.image = image
            }
        })
        const addNewSparePart = () => {
          let maxNo = 0
          for (const sp of spareParts)
            if (sp.data.number > maxNo)
                maxNo = sp.data.number
          maxNo += 1  
          const sp = {key: "sp--" + maxNo, data: {
            id: -maxNo,
            spare_part_image_id: props.sparePartImage, 
            name: "",
            number: maxNo,
            part_number: "",
            x1: 100, y1: 100,
            x2: 50,  y2:50
            }}
          spareParts.push (sp)
        }
        const saveEditMarkerIcon = (sp) => {
            if (state.modifyPart && (state.modifyPart.key == sp.key)) {
                return "fa-duotone fa-floppy-disk"
            }
            return "fa-duotone fa-marker"
        }
        const startMarkerEdit = (sp) => {
            if (sp.draggable) {
                sp.draggable = false
                state.modifyPart = null

            } else {
                sp.draggable = true
                state.modifyPart = sp
            }
        }
        const updateMarkerPos = ({evt}) => {
            const mp = state.modifyPart.data
            mp.x1 = evt.layerX
            mp.y1 = evt.layerY
        }
        const updateMarkerTargetPos = (event) => {
            const mp = state.modifyPart.data
            const x2 = event.evt.layerX - mp.x1
            const y2 = event.evt.layerY - mp.y1
            mp.x2 = x2
            mp.y2 = y2
            event.cancelBubble = true
        }
        const editCellComplete = async () => {
            emit ("update:sparePart", spareParts)
        }
        const confirmDelete = async (sp) => {
            confirm.require({
                message: `Are you sure you want to delete Spart Part ${sp.data.part_number}?`,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    await DC.deleteObject ("spare-part", sp.data.id)
                let idx = -1
                for (let i in spareParts) {
                    if (spareParts [i].data.id == sp.data.id)
                        idx = i
                }
                spareParts.splice (idx, 1)
                },
            });            
        }
        return { konvaConfig, imageConfig, state, spareParts, 
            addNewSparePart, editCellComplete, startMarkerEdit, saveEditMarkerIcon,
            updateMarkerPos, updateMarkerTargetPos, confirmDelete}
    }

}
</script>

<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
    padding-top: 0;
    padding-bottom: 0;
}
.p-inputtext {
    width: 100%;
}
</style>